.container{
    width: 174px;
    height: 222px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    .img_wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
        width: 80px;
        margin-bottom: 30px;
        .img{
            height: 50px;
            width: auto;
            object-fit: contain;
        }
    }
    .text{
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;
        margin-top: 10px;
    }
}
.top_container{
}
.gray{
    background-color: #e7e7e7;
}
.white{
    background-color: #ffffff;
}
.hover_container{
    background-color: #1e72aa;
    width: 258px;
    height: 298px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .hover_title{
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #ffffff;
        margin-bottom: 16px;
    }
    .hover_separator{
        width: 48px;
        background-color: #ffffff;
        height: 2px;
    }
    .hover_text{
        margin-top: 20px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        width: 82%;
    }
}

@media all and (min-width:768px) and (max-width:1023px){
    .top_container{
        width: calc(100% / 3);
        &:nth-child(3){
            display: none;
        }
        &:nth-child(4){
            display: none;
        }
    }
    .container{
        margin: 0;
        width: 100%;
        height: 164px;
        .img_wrapper{
            margin: 0;
            height: 60px;
        }
        .text{
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: #000000;
        }
    }
    
    .img{
        height: 30px;
    }
}

@media all and (max-width:767px){
    .top_container{
        width: calc(100% / 3);
        &:nth-child(3){
            display: none;
        }
        &:nth-child(4){
            display: none;
        }
    }
    .container{
        margin: 0;
        width: 100%;
        height: 164px;
        .img_wrapper{
            margin: 0;
            height: 60px;
        }
        .text{
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: #000000;
        }
    }
    
    .img{
        height: 30px;
    }
}