@import url("https://webfontworld.github.io/kopus/KoPubWorldDotum.css");

.container {
  margin-left: 60px;
  margin-right: 280px;
  display: flex;
  flex-direction: column;
  margin-top: 44px;
  width: 800px;
  .greeting_top_wrapper {
    margin-bottom: 0px;
    padding-bottom: 30px;
    border-bottom: 1px solid #dcdcdc;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .greeting_top_img {
      width: 60%;
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    margin-bottom: 45px;
    width: 100%;
    padding: 20px 10px;
    padding-bottom: 0;
    box-sizing: border-box;
    border-bottom: 1px solid #e6ebe7;
    margin-top: 50px;
    .top_img {
      width: 90%;
      margin: 0 auto;
      object-fit: contain;
    }
    .top_content_box {
      margin: 0 auto;
      width: calc(100% - 120px);
      padding: 0 20px;
      padding-top: 40px;
      padding-bottom: 55px;
      box-sizing: border-box;
      .message {
        width: 100%;
        object-fit: contain;
      }
      .top_content {
        font-size: 15px;
        font-family: "KoPubWorldDotum";
        font-weight: 400;
      }
      .top_content_president {
        font-size: 18px;
        font-family: "KoPubWorldDotum";
        font-weight: 400;
        margin-top: 30px;
      }
    }
    .bot_content_box {
      margin-right: 160px;
      border-right: 1px solid #e6ebe7;
      width: calc(100% - 160px);
      padding-left: 30px;
      padding-top: 45px;
      padding-bottom: 30px;
      padding-right: 30px;
      box-sizing: border-box;
      .bot_content {
        font-size: 15px;
        font-family: "KoPubWorldDotum";
        font-weight: 400;
      }
      .bot_content_president {
        font-size: 18px;
        font-family: "KoPubWorldDotum";
        font-weight: 400;
        margin-top: 40px;
        text-align: right;
      }
    }
  }
}

@media all and (min-width: 768px) and (max-width: 1023px) {
  .container {
    width: calc(100% - 80px);
    padding: 0 40px;
    margin: 0;
    margin-top: 50px;
  }
}

@media all and (max-width: 767px) {
  .container {
    width: calc(100% - 40px);
    padding: 0 20px;
    margin: 0;
    margin-top: 50px;
    .greeting_top_wrapper {
      width: 100%;
      margin-bottom: 25px;
      padding-bottom: 25px;
      .greeting_top_img {
        width: 100%;
      }
    }
    .section {
      .greeting_content_wrapper {
        flex-direction: column;
        .greeting_content_text {
          width: 100%;
        }
      }
    }
  }
}
