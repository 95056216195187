.container {
  margin-left: 60px;
  margin-right: 280px;
  display: flex;
  flex-direction: column;
  margin-top: 44px;
  width: 800px;
  .section {
    display: flex;
    flex-direction: column;
    margin-bottom: 45px;
    width: 100%;
    .list_title {
      margin-bottom: 20px;
    }
    .detail_wrapper {
      .detail_top {
        background-color: #4b7bec;
        padding: 16px;
        margin-top: 25px;
        display: flex;
        justify-content: space-between;
        .detail_name {
          color: white;
          font-weight: 500;
          font-size: 18px;
        }
      }
      .detail_contents_bottom_container {
        border: 1px solid #d4d4d4;
        border-top: none;
        display: flex;
        flex-direction: column;
        .detail_contents_info_row {
          display: flex;
          flex-direction: row;
          min-height: 60px;
          width: 100%;
          border-bottom: 1px solid #d4d4d4;
          .detail_contents_key {
            width: 25%;
            box-sizing: border-box;
            background-color: #e3eaf5;
            font-weight: bold;
            color: #333333;
            min-height: 60px;
            display: flex;
            align-items: center;
            padding: 0 16px;
            border-right: 1px solid #d4d4d4;
          }
          .detail_contents_value {
            flex: 1;
            display: flex;
            align-items: center;
            padding: 16px;
            box-sizing: border-box;
          }
        }
        .detail_contents_info_row_thick {
          min-height: 200px;
          display: flex;
          flex-direction: row;
          width: 100%;
          border-bottom: 1px solid #d4d4d4;
          .detail_contents_key {
            width: 25%;
            box-sizing: border-box;
            background-color: #e3eaf5;
            font-weight: bold;
            color: #333333;
            min-height: 200px;
            display: flex;
            align-items: center;
            padding: 0 16px;
            border-right: 1px solid #d4d4d4;
          }
          .detail_contents_value {
            flex: 1;
            display: flex;
            align-items: center;
            padding: 16px;
            box-sizing: border-box;
          }
        }
      }
      .detail_contents_img_wrapper {
        border: 1px solid #d4d4d4;
        border-bottom: none;
        border-top: none;
        display: flex;
        .detail_contents_img_container {
          width: 25%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 16px;
          box-sizing: border-box;
          border-left: 1px solid #d4d4d4;
          border-bottom: 1px solid #d4d4d4;
          .detail_contents_img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .detail_contents_info_container {
          display: flex;
          flex-direction: column;
          width: 75%;
          .detail_contents_info_row_short {
            display: flex;
            flex-direction: row;
            min-height: 60px;
            width: 100%;
            border-bottom: 1px solid #d4d4d4;
            .detail_contents_key {
              width: calc(100% / 3);
              box-sizing: border-box;
              background-color: #e3eaf5;
              font-weight: bold;
              color: #333333;
              min-height: 60px;
              display: flex;
              align-items: center;
              padding: 0 16px;
              border-right: 1px solid #d4d4d4;
            }
            .detail_contents_value {
              flex: 1;
              display: flex;
              align-items: center;
              padding: 16px;
              box-sizing: border-box;
            }
          }
          .detail_contents_info_row_short_thick {
            min-height: 120px;
            display: flex;
            flex-direction: row;
            width: 100%;
            border-bottom: 1px solid #d4d4d4;
            .detail_contents_key {
              width: calc(100% / 3);
              box-sizing: border-box;
              background-color: #e3eaf5;
              font-weight: bold;
              color: #333333;
              min-height: 120px;
              display: flex;
              align-items: center;
              padding: 0 16px;
              border-right: 1px solid #d4d4d4;
            }
            .detail_contents_value {
              flex: 1;
              display: flex;
              align-items: center;
              padding: 16px;
              box-sizing: border-box;
            }
          }
        }
      }
      .detail_info_row {
        display: flex;
        margin-top: 30px;
        border: 1px solid #d4d4d4;
        border-left: none;
        .detail_info_container {
          display: flex;
          flex-direction: column;
          width: 25%;
          border-left: 1px solid #d4d4d4;
          .detail_column {
            display: flex;
            flex-direction: column;
            width: 100%;
            .detail_column_title {
              width: 100%;
              background-color: #e3eaf5;
              font-weight: bold;
              color: #333333;
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              box-sizing: border-box;
            }
            .detail_contents {
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 500;
              color: #333333;
              min-height: 120px;
              flex: 1;
              padding: 22px;
              box-sizing: border-box;
            }
          }
          .detail_column_title {
            width: 100%;
            background-color: #e3eaf5;
            font-weight: bold;
            color: #333333;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .detail_contents {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            color: #333333;
            min-height: 120px;
            flex: 1;
            padding: 22px;
            box-sizing: border-box;
          }
        }
      }
      .button_wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 20px;
        .button {
          background-color: #d6d6d6;
          color: #333333;
          width: 130px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border-radius: 6px;
          margin-right: 20px;
        }
      }
      .detail_title {
        font-size: 18px;
        font-weight: bold;
        color: #00396a;
        margin-top: 30px;
      }
      .detail_table {
        margin-top: 25px;
        border-top: 1px solid #dcdcdc;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .detail_table_long {
          border-bottom: 1px solid #dcdcdc;
          width: 100%;
          height: 50px;
          display: flex;
          flex-direction: row;
          align-items: center;
          .table_title {
            width: 110px;
            background-color: #e3eaf5;
            font-weight: bold;
            color: #333333;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .table_content {
            width: calc(100% - 125px);
            padding-left: 15px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: #666666;
            font-size: 14px;
          }
        }
        .detail_table_long_warn {
          border-bottom: 1px solid #dcdcdc;
          width: 100%;
          height: 100px;
          display: flex;
          flex-direction: row;
          align-items: center;
          .table_title {
            width: 110px;
            background-color: #e3eaf5;
            font-weight: bold;
            color: #333333;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .table_content {
            width: calc(100% - 125px);
            padding-left: 15px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: #666666;
            font-size: 14px;
          }
        }
        .detail_table_short {
          width: 50%;
          border-bottom: 1px solid #dcdcdc;
          height: 50px;
          display: flex;
          flex-direction: row;
          align-items: center;
          .table_title {
            width: 110px;
            background-color: #e3eaf5;
            font-weight: bold;
            color: #333333;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .table_content {
            width: calc(100% - 125px);
            padding-left: 15px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: #666666;
            font-size: 14px;
          }
        }
      }
      .detail_table2 {
        margin-top: 10px;
        .table2_top_title {
          width: calc(100% - 40px);
          padding: 0 20px;
          background-color: #e3eaf5;
          font-weight: bold;
          color: #333333;
          height: 100px;
          display: flex;
          align-items: center;
        }
        .table2_wrapper {
          display: flex;
          flex-direction: row;
          height: 250px;
          .table2_img_wrapper {
            width: 40%;
            height: 100%;
            .table2_img {
              height: 100%;
              width: 100%;
              object-fit: contain;
            }
          }
          .table2_sticky {
            display: flex;
            flex-direction: column;
            width: 60%;
            .table2_row {
              height: 50px;
              display: flex;
              flex-direction: row;
              width: 100%;
              .table2_title {
                border: 1px solid #dcdcdc;
                border-top: none;
                font-weight: 500;
                color: #333333;
                width: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .table2_content {
                font-weight: 500;
                font-size: 12px;
                color: #666666;
                border-bottom: 1px solid #dcdcdc;
                width: calc(100% - 120px);
                padding: 0 10px;
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
    }
    .result_wrapper {
      margin-top: 60px;
      .result_title {
        width: 100%;
        text-align: center;
        font-size: 18px;
        color: #00369a;
        font-weight: bold;
        margin-bottom: 30px;
      }
      .result_top_bar {
        width: calc(100% - 40px);
        border-top: solid 1px #d2d2d2;
        border-bottom: solid 1px #d2d2d2;
        height: 60px;
        background-color: #ebf3ff;
        display: flex;
        flex-direction: row;
        padding: 0 20px;
        align-items: center;
        .result_top_bar_content {
          &:nth-child(1) {
            width: 80px;
            text-align: center;
          }
          &:nth-child(2) {
            width: 239px;
            text-align: center;
          }
          &:nth-child(3) {
            width: 110px;
            text-align: center;
          }
          &:nth-child(4) {
            width: 100px;
            text-align: center;
          }
          &:nth-child(5) {
            width: 80px;
            text-align: center;
          }
          &:nth-child(6) {
            width: 80px;
            text-align: center;
          }
          &:nth-child(7) {
            width: 117px;
            text-align: center;
          }
        }
      }
    }
    .search_wrapper {
      margin-top: 10px;
      .search_title {
        font-size: 22px;
        font-weight: 500;
        color: #666666;
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
      }
      .search_row {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        margin-top: 15px;
        align-items: center;
        .search_row_name {
          font-size: 22px;
          font-weight: bold;
          color: #00396a;
          width: 100px;
        }
        .search_row_input {
          border: solid 1px #d6d6d6;
          outline: none;
          padding: 13px 20px;
          width: 410px;
          margin-right: 10px;
          font-family: inherit;
        }
        .search_row_button {
          width: 130px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #b1b1b1;
          font-size: 20px;
          font-weight: bold;
          color: #ffffff;
          cursor: pointer;
        }
        .active {
          background-color: #00396a;
        }
      }
    }
  }
}
.year {
  color: #ff0000;
  font-weight: bold;
  font-size: 20px;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
.testingcontent_wrapper {
  border-bottom: 1px solid #d2d2d2;
  height: 65px;
  width: calc(100% - 40px);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  .testingcontent_ord_cd {
    width: 80px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .testingcontent_data1 {
    width: 239px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .testingcontent_data10 {
    width: 110px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .testingcontent_data13 {
    width: 100px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .testingcontent_data11 {
    width: 80px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .testingcontent_data17 {
    width: 80px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .testingcontent_data14 {
    width: 117px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.pagenumberlist {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;
  align-items: center;
  .previous {
    width: 0px;
    height: 0px;
    border-top: 7.5px solid transparent;
    border-right: 15px solid #525252;
    border-bottom: 7.5px solid transparent;
    margin-right: 8px;
  }
  .next {
    width: 0px;
    height: 0px;
    border-top: 7.5px solid transparent;
    border-left: 15px solid #525252;
    border-bottom: 7.5px solid transparent;
    margin-left: 8px;
  }
}

.pagenum_wrapper {
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: rgba(72, 110, 153, 0.1);
  }
  .pagenum {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #616161;
    cursor: pointer;
  }
}
.pagenum_selected {
  width: 40px;
  height: 40px;
  background-color: rgba(72, 110, 153, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  .pagenum {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #486e99;
    cursor: pointer;
  }
}
