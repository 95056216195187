.container {
  margin-left: 60px;
  margin-right: 280px;
  display: flex;
  flex-direction: column;
  margin-top: 44px;
  width: 800px;
  .section {
    display: flex;
    flex-direction: column;
    margin-bottom: 45px;
    width: 100%;
    .detail_title_wrapper {
      display: flex;
      flex-direction: column;
      border-top: 2px solid #6083b2;
      .detail_title {
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;
      }
      .detail_date_wrapper {
        display: flex;
        flex-direction: row;
        .detail_date_text {
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000000;
        }
        .detail_date {
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #767676;
        }
      }
    }
    .pagenumberlist {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 50px;
      align-items: center;
      .previous {
        width: 0px;
        height: 0px;
        border-top: 7.5px solid transparent;
        border-right: 15px solid #525252;
        border-bottom: 7.5px solid transparent;
        margin-right: 8px;
      }
      .next {
        width: 0px;
        height: 0px;
        border-top: 7.5px solid transparent;
        border-left: 15px solid #525252;
        border-bottom: 7.5px solid transparent;
        margin-left: 8px;
      }
    }
    .title_normal {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }
  }
}

.no_data {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  width: 100%;
  margin-top: 24px;
}

.searchContainer {
  display: flex;
  width: 100%;
  column-gap: 24px;
  margin-top: 24px;
  align-items: center;
  .searchTitle {
    font-size: 20px;
    font-weight: 600;
  }
  .searchInput {
    flex: 1;
    font-size: 16px;
    padding: 12px;
    border-radius: 0px;
    border-width: 1px;
    outline: none;
  }
  .searchButton {
    font-size: 15px;
    padding: 12px;
    font-weight: bold;
    width: 120px;
    background-color: #0b0b35;
    color: #ffffff;
    border: none;
    cursor: pointer;
  }
}

.official_top {
  background-color: #ebf3ff;
  border-top: 1px solid #dcdcdc;
  margin-top: 46px;
  min-height: 30px !important;
}
.official_content {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 16px 0;
  min-height: 60px;
  align-items: center;
  border-bottom: 1px solid #dcdcdd;

  cursor: pointer;
  .official_id {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    margin-left: 48px;
    width: 106px;
  }
  .official_title {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    width: 400px;
    padding-right: 20px;
  }
  .official_date {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #767676;
    width: 120px;
  }
  .official_top_date {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    width: 100px;
    padding-right: 20px;
  }
  .official_top_file {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    display: flex;
  }
}

.pagenum_wrapper {
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: rgba(72, 110, 153, 0.1);
  }
  .pagenum {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #616161;
    cursor: pointer;
  }
}
.pagenum_selected {
  width: 40px;
  height: 40px;
  background-color: rgba(72, 110, 153, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  .pagenum {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #486e99;
    cursor: pointer;
  }
}

@media all and (min-width: 768px) and (max-width: 1023px) {
  .container {
    width: calc(100% - 80px);
    padding: 0 40px;
    margin: 0;
    margin-top: 50px;
  }
}

@media all and (max-width: 767px) {
  .container {
    width: calc(100% - 40px);
    padding: 0 20px;
    margin: 0;
    margin-top: 50px;
    .section {
      .official_content {
        .official_id {
          margin-left: 15px;
          width: 15%;
        }
        .official_content {
          width: 60%;
        }
        .official_title {
          width: 60%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .official_date {
        }
      }
    }
  }
}
