.footer {
  width: 100vw;
  background-color: #2d3034;
  display: flex;
  justify-content: center;
  height: 170px;
}
.footer-sticky {
  width: 100%;
  max-width: 1520px;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.footer-left {
  text-align: left;
  width: calc(60% - 200px);
  margin-left: 200px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.footer-right {
  text-align: right;
  width: calc(40% - 100px);
  margin-right: 100px;
}
.footer-text-sticky {
  display: flex;
  flex-direction: column;
}
.footer-logo {
  width: 190px;
  height: 38px;
  object-fit: contain;
  margin-top: 45px;
  margin-right: 100px;
}
.footer-text-wrapper {
  display: flex;
  flex-direction: row;
}
.footer-text {
  height: 20px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-top: 3px;
}
.footer-bottom-wrapper {
  margin-top: 31px;
  display: flex;
  flex-direction: row;
}
.footer-bottom-text {
  height: 19px;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.footer-bottom-text:nth-child(1) {
  padding-right: 20px;
}
.footer-bottom-text:nth-child(2) {
  padding: 0 20px;
  border-left: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
}
.footer-bottom-text:nth-child(3) {
  padding: 0 20px;
  border-right: 1px solid #ffffff;
}
.footer-bottom-text:nth-child(4) {
  padding: 0 20px;
  border-right: 1px solid #ffffff;
}
.footer-bottom-text:nth-child(5) {
  padding-left: 20px;
}
.copyright {
  height: 19px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9b9b9b;
}

body {
  margin: 0;
  overflow-x: hidden;
}
a {
  text-decoration: none;
  color: #000000;
}
.container {
  width: 100vw;
}
.header {
  height: 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  border-bottom: 1px solid #e0e0e0;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff;
  z-index: 9999;
}
.header-oval {
  height: 25px;
  background-color: rgb(13, 13, 75);
  width: 100%;
}
.header-contents {
  width: 100vw;
  max-width: 1520px;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.contents-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: calc(70% - 80px);
  margin-left: 80px;
}
.contents-right {
  width: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.content-wrapper {
  width: 160px;
  margin-left: 20px;
  height: auto;
  min-height: 100px;
  position: relative;
  top: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  .header-content-shown {
    width: 140px;
    height: 0;
  }
  .header-shown-text {
    display: none;
  }
}
.header-hover-content {
  display: none;
}

.contents-left:hover {
  .menu1:hover {
    border-bottom: 5px solid #00284b;
  }
  .menu2:hover {
    border-bottom: 5px solid #00284b;
  }
  .menu3:hover {
    border-bottom: 5px solid #00284b;
  }
  .menu4:hover {
    border-bottom: 5px solid #00284b;
  }
  .menu5:hover {
    border-bottom: 5px solid #00284b;
  }

  .header-hover-content {
    display: flex;
    flex-direction: row;
    width: 100vw;
    position: fixed;
    justify-content: center;
    top: 125px;
    left: 0;
    background-color: #ffffff;
    border-top: 1px solid #9b9b9b;
    box-shadow: 0 4px 12px #b3b2b2;
  }
  .hover-sticky {
    width: 100%;
    max-width: 1520px;
  }
  .hover-content-left {
    display: flex;
    flex-direction: row;
    width: calc(70% - 80px);
    margin-left: 80px;
    justify-content: flex-start;
  }
  .header-content-shown {
    display: flex;
    flex-direction: column;
    width: 117px;
    margin-left: 48px;
    position: relative;
    padding-top: 25px;
    height: 450px;
    padding-left: 15px;
    &:nth-child(1) {
      width: 111px;
      margin-left: 54px;
    }
    &:nth-child(2) {
      width: 111px;
      margin-left: 54px;
    }
    &:nth-child(5) {
      width: 98px;
      margin-left: 67px;
    }
  }
  .header-shown-text {
    display: flex;
    font-size: 14px;
    color: #555555;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-decoration: none;
    margin-top: 25px;
    &:nth-child(1) {
      margin-top: 0;
    }
  }
}
.content {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #00284b;
  position: relative;
}
.content__last {
  margin-right: 109px;
}
.header-logo-sticky {
  width: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-right: 100px;
}
.header-logo {
  width: 190px;
  height: auto;
  object-fit: contain;
}
.container {
  width: 100vw;
}
.contents-none {
  display: none;
}

@media all and (min-width: 768px) and (max-width: 1023px) {
  .header-logo-sticky {
    margin-right: 0;
  }
  .contents-left {
    display: none;
  }
  .header-oval {
    width: 100%;
    height: auto;
  }
  .contents-none {
    display: flex;
    height: 100%;
    align-items: center;
    width: 50%;
    margin-left: 40px;
  }
  .icon {
    width: 40px;
    height: 26px;
    object-fit: contain;
  }
  .header-logo {
    width: 118px;
    height: 25px;
    object-fit: contain;
    margin-right: 100px;
  }
  .footer {
    background-image: url("./footer-tab.svg");
    background-position: center center;
    background-repeat: no-repeat;
    height: 131px;
  }
  .footer-text-wrapper {
    margin-top: 23px;
    margin-left: 50px;
  }
  .footer-text-sticky {
    margin-top: 0;
  }
  .footer-text {
    height: auto;
    margin-top: 0;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    &:nth-child(2) {
      margin-bottom: 28px;
    }
  }
  .footer-bottom-wrapper {
    margin-top: 0;
    margin-left: 50px;
    align-items: center;
    .footer-bottom-text {
      font-size: 10px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      display: flex;
      align-items: center;
      &:nth-child(3) {
        margin-right: 0;
        padding-left: 16px;
      }
      &:nth-child(2) {
        padding: 0 16px;
      }
      &:nth-child(1) {
        padding-right: 16px;
      }
    }
    .copyright {
      font-size: 8px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      height: auto;
      margin-left: 40px;
      margin-top: 10px;
    }
  }
  .footer-logo {
    width: 209px;
    height: 45px;
    object-fit: contain;
    margin-top: 0;
    margin-right: 20px;
  }
}

@media all and (max-width: 767px) {
  .contents-left {
    display: none;
  }
  .header {
    height: 55px;
  }
  .header-oval {
    width: 100%;
    height: auto;
  }
  .header-contents {
    height: 50px;
  }
  .contents-none {
    display: flex;
    height: 100%;
    align-items: center;
    width: 50%;
    margin-left: 20px;
  }
  .icon {
    width: 24px;
    height: 17px;
    object-fit: contain;
  }
  .header-logo {
    width: 118px;
    height: 25px;
    object-fit: contain;
    margin-right: 20px;
  }
  .footer {
    background-image: url("./footer-tab.svg");
    background-position: center center;
    background-repeat: no-repeat;
    height: 92px;
    width: 100%;
    display: none;
  }
  .footer-text-wrapper {
    margin-top: 23px;
    margin-left: 15px;
  }
  .footer-text-sticky {
    margin-top: 0;
  }
  .footer-text {
    height: auto;
    margin-top: 0;
    font-size: 8px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    display: flex;
    flex-direction: column;
  }
  .footer-bottom-wrapper {
    margin-top: 0;
    margin-left: 15px;
    align-items: center;
    .footer-bottom-text {
      font-size: 7px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      display: flex;
      align-items: center;
      &:nth-child(3) {
        margin-right: 0;
        padding-left: 5px;
      }
      &:nth-child(2) {
        padding: 0 5px;
      }
      &:nth-child(1) {
        padding-right: 5px;
      }
    }
    .copyright {
      font-size: 5px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      height: auto;
      margin-left: 0px;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
    }
  }
  .footer-logo {
    width: 118px;
    height: 25px;
    object-fit: contain;
    margin-top: 0;
    margin-right: 15px;
  }
}
