.body_forms {
  width: 260px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.forms {
  width: 260px;
  height: 42%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login {
  background-color: #00396a;
}
.search {
  background-color: #9e9fa0;
}
.forms_sticky {
  width: 170px;
}
.forms_title {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  width: 100%;
  text-align: center;
}
.input_wrapper {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}
.input_sticky {
  width: 100%;
  height: 50px;
}
.input {
  width: calc(100% - 20px);
  height: 20px;
  padding: 10px 10px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6f6f6f;
  outline: none;
  border: none;
  margin-bottom: 7px;
}
.input__last {
  margin-top: 10px;
}
.input::placeholder {
}
.input_btn {
  width: 80px;
  height: 40px;
  background-color: #0b0b35;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.forms_footer {
  display: flex;
  flex-direction: row;
}
.checkbox {
  width: 23px;
  height: 23px;
  margin: 0;
}
.forms_footer_text {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  padding: 0 15px;
  height: 18px;
  margin: 2.5px 0;
}
.forms_footer_text__last {
  border-left: 1px solid #ffffff;
}
.bottom_buttons_wrapper {
  width: 260px;
  display: flex;
  flex-direction: row;
  height: 16%;
}
.bottom_button {
  width: calc(50% - 2px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  border: 1px solid #e2e2e2;
  background-color: #f3f3f3;
}
.button_left_icon {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.button_text {
  text-align: center;
}
@media all and (min-width: 768px) and (max-width: 1023px) {
  .body_forms {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    .forms {
      width: 50%;
      height: 164px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .input_sticky {
    display: none;
  }
  .input_btn {
    width: 100%;
  }
  .forms_sticky {
    width: 70%;
    text-align: center;
  }
}

@media all and (max-width: 767px) {
  .body_forms {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    .forms {
      width: 50%;
      height: 134px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .input_sticky {
    display: none;
  }
  .input_btn {
    width: 100%;
  }
  .forms_sticky {
    width: 70%;
    text-align: center;
  }
}
