.container {
  margin-left: 60px;
  margin-right: 280px;
  display: flex;
  flex-direction: column;
  margin-top: 44px;
  width: 800px;
  .greeting_top_wrapper {
    margin-bottom: 40px;
    padding-bottom: 30px;
    border-bottom: 1px solid #dcdcdc;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .greeting_top_img {
      width: 60%;
    }
  }
  .mission_top_wrapper {
    display: flex;
    flex-direction: row;
    width: 50%;
    height: 60px;
    border: 1px solid #dcdcdc;
    margin: 0 auto;
    margin-bottom: 60px;

    .mission_top_content {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #666666;
      cursor: pointer;
      &:hover {
        background-color: #2d4c6a;
        color: #ffffff;
      }
      &:nth-child(4) {
        border-right: none;
      }
    }
    .mission_top_selected {
      background-color: #2d4c6a;
      color: #ffffff;
    }
  }
  .section {
    display: flex;
    flex-direction: column;
    margin-bottom: 45px;
    width: 100%;
    .greeting_title {
      font-size: 30px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }
    .doctor_subtitle {
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #444444;
      margin-top: 16px;
      margin-bottom: 45px;
    }
    .doctor_img {
      margin-bottom: 40px;
      width: 100%;
      height: auto;
      object-fit: cover;
      box-shadow: 0px 0px 7px 4px #efefef;
    }
  }
}

@media all and (min-width: 768px) and (max-width: 1023px) {
  .container {
    width: calc(100% - 80px);
    padding: 0 40px;
    margin: 0;
    margin-top: 50px;
  }
  .doctor_img {
    height: auto;
    width: 100%;
  }
}

@media all and (max-width: 767px) {
  .container {
    width: calc(100% - 40px);
    padding: 0 20px;
    margin: 0;
    margin-top: 50px;
    .section {
      .greeting_title {
        display: none;
      }
      .doctor_subtitle {
        display: none;
      }
      .doctor_img {
        width: 100%;
        height: auto;
      }
    }
  }
}
