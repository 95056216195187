.container{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 125px;
}
.sticky_container{
    max-width: 960px;
    display: flex;
    flex-direction: column;
}
.title{
    font-size: 28px;
    font-weight: bold;
    margin-top: 60px;
    margin-bottom: 25px;
}
.subtitle{
    font-size: 24px;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 30px;
}
.text{
    font-size: 15px;
    font-weight: 500;
    color: #5b5b5b;
    line-height: 2.13;
}
.link{
    font-size: 15px;
    font-weight: 500;
    color: #000000;
    line-height: 2.13;
}