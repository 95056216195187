.sidebar_title {
  width: 100%;
  height: 190px;
  background-color: #64a4e3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('./rect.svg');
}
.sidebar_selected {
  width: 100%;
  height: 60px;
  
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #64a4e3;
  font-weight: bold;
  color: #ffffff;
}
.sidebar_content:hover {
  background-color: #64a4e3;
  font-weight: bold;
  color: #ffffff;
}
.sidebar {
  display: flex;
  flex-direction: column;
  width: 230px;
  height: 692px;
  position: relative;
  top: -97px;

  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.75);
}
.sidebar_title_img {
  width: 54px;
  height: 54px;
  object-fit: contain;
  margin-bottom: 4px;
}
.sidebar_title_text {
  
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-top: 4px;
}
.sidebar_content {
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #666666;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.side_box_shadow {
    position: absolute;
    height: 100%;
    background: linear-gradient(rgba(255,255,255,0) 30%, rgba(255,255,255,1) 95%);;
    z-index: 10;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    margin-left: -10px;
    padding:0px 10px ;
    box-sizing: border-box;
}
@media all and (min-width: 768px) and (max-width: 1023px) {
  .sidebar_title_img{
        display: none;
    }
    .sidebar{
        position: relative;
        top: 0;
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: row;
        .side_box_shadow{
            display: flex;
            flex-direction: row;
            .sidebar_title{
                height: 100%;
                width: 40%;
                background-color: #2d4c6a;
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #ffffff;
            }
            .sidebar_content{
                display: none;
            }
            .sidebar_selected{
                background-color: #002740;
                justify-content: flex-start;
                padding-left: 40px;
            }
        }
    }
}

@media all and (max-width: 767px) {
  .sidebar_title_img{
    display: none;
}
.sidebar{
    position: relative;
    top: 0;
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    .side_box_shadow{
        display: flex;
        flex-direction: row;
        .sidebar_title{
            height: 100%;
            width: 40%;
            background-color: #2d4c6a;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;
        }
        .sidebar_content{
            display: none;
        }
        .sidebar_selected{
            background-color: #002740;
            justify-content: flex-start;
            padding-left: 40px;
        }
    }
}
}
