.container {
  margin-left: 60px;
  margin-right: 280px;
  display: flex;
  flex-direction: column;
  margin-top: 44px;
  width: 800px;
  @media all and (max-width: 767px) {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
.section {
  display: flex;
  flex-direction: column;
  margin-bottom: 45px;
  width: 100%;
}
.body_top_bg_wrapper {
  margin-top: 125px;
}
.body_top_bg {
  height: 200px;
  width: 100vw;
  object-fit: cover;
}
.detail_title_wrapper {
  display: flex;
  flex-direction: column;
  border-top: 2px solid #6083b2;
  padding: 15px 20px;
  width: calc(100% - 40px);
  border-bottom: 1px solid #d2d2d2;
}
.detail_title {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 9px;
}
.detail_contents_wrapper {
  padding: 50px 20px;
  width: calc(100% - 40px);
}
.list_btn_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.list_btn {
  text-decoration: none;
  border: 1px solid #6083b2;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6083b2;
  padding: 11px 40px;
  cursor: pointer;
}
.detail_date_wrapper {
  display: flex;
  flex-direction: row;
}
.detail_date_text {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.detail_date {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #767676;
  margin-left: 12px;
}

.body {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.body_contents {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}
.sidebar_title {
  width: 100%;
  height: 190px;
  background-color: #6083b2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("./rect.svg");
}
.sidebar_selected {
  width: 100%;
  height: 60px;

  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #6083b2;
  font-weight: bold;
  color: #ffffff;
}
.sidebar_content:hover {
  background-color: #6083b2;
  font-weight: bold;
  color: #ffffff;
}
.sidebar {
  display: flex;
  flex-direction: column;
  width: 230px;
  height: 692px;
  position: relative;
  top: -97px;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.75);
}
.sidebar_title_img {
  width: 54px;
  height: 54px;
  object-fit: contain;
  margin-bottom: 4px;
}
.sidebar_title_text {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-top: 4px;
}
.sidebar_content {
  width: 100%;
  height: 60px;
  background-color: #ffffff;

  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #666666;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.side_box_shadow {
  position: absolute;
  height: 100%;
  background: linear-gradient(
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 1) 80%
  );
  z-index: 10;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  margin-left: -10px;
  padding: 0px 10px;
  box-sizing: border-box;
}
.forms-wrapper {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 100px;
  top: 125px;
}
.forms {
  width: 236px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login {
  background-color: #00396a;
  height: 153px;
}
.search {
  background-color: #9e9fa0;
  height: 190px;
}
.forms-sticky {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.forms-title {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-left: 28px;
  margin-bottom: 9px;
}
.input-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 28px;
  width: calc(100% - 56px);
  margin-top: 9px;
}
.input-sticky {
  width: 197px;
  margin-right: 13px;
}
.input {
  width: 160px;
  height: 15px;
  padding: 10px 10px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6f6f6f;
  outline: none;
  border: none;
  margin-bottom: 7px;
}
.input__last {
  margin-top: 10px;
}
.input::placeholder {
}
.input-btn {
  width: 100%;
  height: 45px;
  background-color: #0b0b35;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.forms-footer {
  display: flex;
  flex-direction: row;
}
.checkbox {
  width: 23px;
  height: 23px;
  margin: 0;
}
.forms-footer-text {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  padding: 0 15px;
  height: 18px;
  margin: 2.5px 0;
}
.forms-footer-text__last {
  border-left: 1px solid #ffffff;
}
.forms-btn-wrapper {
  width: 236px;
  height: 125px;
  display: flex;
  flex-direction: row;
}
.forms-btn {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.forms-btn__gray {
  background-color: #e7e7e7;
}
.forms-btn__blue {
  background-color: #6183bb;
}
.forms-btn-icon {
  width: 39px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 7px;
}
.forms-btn-text {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #666666;
  margin-top: 7px;
}
.light {
  color: #ffffff;
}
.file_download_wrapper {
  width: 100%;
  border-top: solid 1px #d2d2d2;
  border-bottom: solid 1px #d2d2d2;
  padding: 6px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}
.file_title_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}
.file_title {
  font-size: 15px;
  font-weight: bold;
  margin: 0 10px;
  width: 100px;
}
.file_filename {
  font-size: 14px;
  font-weight: 500;
  color: #666666;
}
.file_download_button {
  padding: 10px 16px;
  background-color: #434343;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
}
@media all and (min-width: 768px) and (max-width: 1023px) {
  .body_top_bg {
    display: none;
  }
  .body_top_bg_wrapper {
    width: 100%;
    height: 200px;
    background-color: #d8d8d8;
  }
  .body {
  }
  .body_contents {
    display: flex;
    flex-direction: column;
  }
  .sidebar_title_img {
    display: none;
  }
  .sidebar {
    position: relative;
    top: 0;
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
  }
  .side_box_shadow {
    display: flex;
    flex-direction: row;
  }
  .sidebar_title {
    height: 100%;
    width: 40%;
    background-color: #2d4c6a;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
  .sidebar_content {
    display: none;
  }
  .sidebar_selected {
    background-color: #002740;
    justify-content: flex-start;
    padding-left: 40px;
  }
  .forms-wrapper {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    flex-direction: row;
  }
  .forms-btn-wrapper {
    display: none;
  }
  .input-wrapper {
    display: none;
  }
  .forms {
    height: 50px;
    width: 50%;
  }
  .forms-title {
    margin: 0;
  }
  .forms-sticky {
    align-items: center;
    justify-content: center;
  }
}

@media all and (max-width: 767px) {
  .forms-wrapper {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    flex-direction: row;
  }
  .forms-btn-wrapper {
    display: none;
  }
  .input-wrapper {
    display: none;
  }
  .forms {
    height: 50px;
    width: 50%;
  }
  .forms-title {
    margin: 0;
  }
  .forms-sticky {
    align-items: center;
    justify-content: center;
  }
  .detail_contents_wrapper {
    width: 100%;
    padding: 0;

    img {
      width: 100% !important;
    }
  }
}
