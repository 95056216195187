.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 60px;
  width: 940px;
  height: 50px;
}
.title_wrapper {
  width: 120px;
  background-color: #00396e;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.title {
  font-size: 15px;
  color: #ffffff;
  font-weight: bold;
}
.img_wrapper {
  width: 820px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border: solid 1px #d5dee5;
  height: 100%;
  align-items: center;
}
.content_wrapper {
  display: flex;
  flex-direction: row;
  margin: 0 10px;
  width: calc((100% - 86px) / 3);
}
.content_title {
  font-size: 16px;
  color: #4b4b4b;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-right: 1px solid #dedede;
}
.content_title_last {
  border: none;
}
.site_img {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .container {
    width: 100%;
  }

  .img_wrapper {
    width: 100%;
    flex-direction: column;
  }

  .content_wrapper {
    width: 100%;
  }
}
