.container {
  margin-left: 60px;
  margin-right: 280px;
  display: flex;
  flex-direction: column;
  margin-top: 44px;
  width: 800px;
  .section {
    display: flex;
    flex-direction: column;
    margin-bottom: 45px;
    width: 100%;
    &:nth-child(4) {
      margin-bottom: 150px;
    }
  }
}

.title {
  font-weight: bold;
  font-size: 18px;
  margin-top: 60px;
  margin-bottom: 15px;
}

.sub_title {
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  color: #527ff8;
}

.box {
  padding: 10px 0px;
  margin-top: 40px;
}

.box_title {
  font-weight: bold;
  font-size: 16px;
  color: black;
}

.box_content_row {
  display: flex;
  flex-direction: row;
}

.box_content_row_key {
  font-weight: 500;
  font-size: 14px;
}

.box_content_row_value {
  outline: none;
  border: none;
  margin-left: 5px;
}

.box_content_checkbox_label_1 {
  font-size: 14px;
  font-weight: 500;
  width: 110px;
  margin-left: 3px;
}

.box_content_checkbox_label_2 {
  font-size: 14px;
  font-weight: 500;
  width: 210px;
  margin-left: 3px;
}

.box_content_checkbox_label {
  font-size: 14px;
  font-weight: 500;
  margin-left: 3px;
}

.agree_box_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0px;
}

.agree_text {
  font-size: 12px;
  font-weight: 500;
}

.part_title {
  font-size: 14px;
  font-weight: bold;
  margin-top: 30px;
}

.part_content {
  font-size: 12px;
  font-weight: 500;
  margin-left: 20px;
  color: #527ff8;
}

.send_button_wrapper {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

.send_button {
  background-color: #50657e;
  border-radius: 10px;
  color: white;
  letter-spacing: 5px;
  align-self: flex-start;
  font-size: 14px;
  font-weight: bold;
  padding: 15px 30px;
  cursor: pointer;
}

.shinwon_irb_wrapper {
  display: flex;
  justify-content: center;
  padding: 60px 0px;
}

.shinwon_irb {
  width: 33%;
}
