.container{
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.sticky_container{
    height: 100%;
    width: 960px;
    display: flex;
    flex-direction: column;
}
.header{
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100vw;
    border: solid 1px #e8e8e8;
    justify-content: center;
}
.header_sticky{
    width: 960px;
}
.logo{
    width: 223px;
    height: 44px;
    object-fit: contain;
}
.body_container{
    margin-top: 40px;
    width: 100%;
    max-width: 960px;
    margin-bottom: 50px;
}
.page_title{
    font-size: 40px;
    color: #00396a;
    font-weight: bold;
    margin-bottom: 12px;
}
.page_desc{
    font-size: 28px;
    font-weight: 500;
    color: #868686;
    margin-bottom: 25px;
}
.page_box{
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 57, 106, 0.06);
    margin-bottom: 40px;
}
.box_text{
    font-size: 20px;
    font-weight: 500;
    color: #000000;
    margin-right: 10px;
}
.box_email{
    font-size: 25px;
    font-weight: bold;
    color: #000000;
    margin-left: 10px;
}
.page_table{
    display: flex;
    flex-direction: column;
    border-top: 2px solid #00396a;
    width: 100%;
    margin-bottom: 40px;
}
.table_row{
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #dfdfdf;
}
.row_title{
    width: 135px;
    font-weight: 500;
    color: #000000;
    background-color: #eff3f6;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.row_text{
    margin-left: 20px;
    color: #666666;
}
.row_text_bold{
    font-weight: bold;
    margin-left: 20px;
    color: #000000;
}
.page_title_big{
    font-size: 45px;
    color: #00396a;
    font-weight: bold;
    margin-bottom: 12px;
}
.subtitle_wrapper{
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
}
.subtitle_mark{
    width: 10px;
    height: 24px;
    background-color: #6083b2;
    margin-right: 10px;
}
.subtitle_text{
    font-size: 25px;
    font-weight: bold;
}
.text{
    color: #666666;
    margin-left: 25px;
}
.button_wrapper{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
}
.button{
    border-radius: 6px;
    background-color: #00396a;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    width: 130px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.line_break{
    white-space: pre-line;
}