.img {
  width: 100%;
  object-fit: cover;
  padding: 30px 0 30px 0;
}
.container {
  margin-left: 60px;
  margin-right: 280px;
  display: flex;
  flex-direction: column;
  margin-top: 44px;
  width: 800px;
  .greeting_top_wrapper {
    margin-bottom: 40px;
    padding-bottom: 30px;
    border-bottom: 1px solid #dcdcdc;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .greeting_top_img {
      width: 60%;
    }
  }
  .greeting_title {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .section {
    margin-bottom: 45px;
    width: 100%;
  }
}
