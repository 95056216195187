html {
  scroll-behavior: smooth;
}

.container {
  margin-left: 60px;
  margin-right: 280px;
  display: flex;
  flex-direction: column;
  margin-top: 44px;
  width: 800px;
  .section {
    display: flex;
    flex-direction: column;
    margin-bottom: 45px;
    width: 100%;
    &:nth-child(4) {
      margin-bottom: 150px;
    }

    .separation_wrapper {
      border: 1px solid #cccccc;
      width: calc(100% - 3px);
      height: 60px;
      display: flex;
      flex-direction: row;
      cursor: pointer;
      margin-top: 50px;
      .separation_content {
        height: 100%;
        width: calc((100% - 2px) / 3);
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #666666;
        display: flex;
        align-items: center;
        justify-content: center;
        &:nth-child(1) {
          border-right: 1px solid #cccccc;
        }
        &:nth-child(2) {
          border-right: 1px solid #cccccc;
        }
        &:hover {
          background-color: #223a56;
          color: #ffffff;
        }
      }
      .separation_selected {
        height: 100%;
        width: calc((100% - 2px) / 3);
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        background-color: #223a56;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.content_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.content_section {
}

.title {
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid #e4e2e2;
  padding: 10px 10px;
}

.content {
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
  padding: 40px 20px;
}

.irb_define_wrapper {
  display: flex;
  justify-content: center;
}

.irb_target_container {
  padding: 40px 15px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.irb_target_item {
  display: flex;
  border: 1px solid #e4e2e2;
}

.irb_target_item_key {
  width: 20%;
  background-color: #50657e;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 16px;
}

.irb_target_item_value {
  padding: 20px 25px;
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 26px;
  font-weight: 500;
  width: 80%;
}

.irb_define {
  object-fit: contain;
  width: 50%;
}

.irb_flow_wrapper {
  display: flex;
  padding: 60px 0px;
  justify-content: center;
}

.irb_flow {
  object-fit: contain;
  width: 95%;
}

.shinwon_irb_wrapper {
  display: flex;
  justify-content: center;
  padding: 60px 0px;
}

.shinwon_irb {
  width: 33%;
}
