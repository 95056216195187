.container {
  margin-left: 60px;
  margin-right: 280px;
  display: flex;
  flex-direction: column;
  margin-top: 44px;
  width: 800px;
  .section {
    display: flex;
    flex-direction: column;
    margin-bottom: 45px;
    width: 100%;
    .top_text {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      margin-bottom: 10px;
    }
  }
}
.img {
  width: 576px;
  height: 374px;
  margin: 18px 321px 45px 10px;
  object-fit: contain;
}
.download_button {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: #00396a;
  border-radius: 6px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  margin-left: 40px;
  width: 200px;
  margin-bottom: 20px;
  cursor: pointer;
}

.download_button_2 {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  background-color: #ffffff;
  border-radius: 6px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  margin-left: 40px;
  width: 200px;
  margin-bottom: 20px;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #00396a;
}
.type_content_wrapper {
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;
  .type_content_text {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    color: #303030;
    margin-left: 30px;
  }
  .note {
    font-size: 12px;
    font-weight: bold;
  }
  .note_text {
    font-size: 12px;
    color: #cccccc;
  }
}

.type_title_wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 18px;
  width: 100%;
  .rect {
    width: 20px;
    height: 20px;
    background-color: #64a4e3;
  }
  .type_content_title {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #64a4e3;
    margin-left: 10px;
    padding-bottom: 18px;
    border-bottom: 1px solid #cccccc;
    width: calc(100% - 30px);
  }
}

@media all and (min-width: 768px) and (max-width: 1023px) {
  .container {
    width: calc(100% - 80px);
    padding: 0 40px;
    margin: 0;
    margin-top: 50px;
  }
}

@media all and (max-width: 767px) {
}
