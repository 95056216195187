.container{
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.sticky_container{
    height: 100%;
    width: 960px;
    display: flex;
    flex-direction: column;
}
.header{
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100vw;
    border: solid 1px #e8e8e8;
    justify-content: center;
}
.header_sticky{
    width: 960px;
}
.logo{
    width: 223px;
    height: 44px;
    object-fit: contain;
}
.body_container{
    margin-top: 40px;
    width: 100%;
    max-width: 960px;
    margin-bottom: 100px;
    min-height: 60vh;
}
.page_title{
    font-size: 40px;
    color: #00396a;
    font-weight: bold;
    margin-bottom: 12px;
}
.page_desc{
    font-size: 28px;
    font-weight: 500;
    color: #868686;
    margin-bottom: 25px;
}
.page_box{
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 57, 106, 0.06);
    margin-bottom: 40px;
}
.box_text{
    font-size: 20px;
    font-weight: 500;
    color: #000000;
    margin-right: 10px;
}
.box_email{
    font-size: 25px;
    font-weight: bold;
    color: #000000;
    margin-left: 10px;
}
.list_wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.list_togglebar{
    height: 50px;
    border-bottom: solid 1px #979797;
    width: 100%;
    display: flex;
    flex-direction: row;
}
.list_toggle_text{
    cursor: pointer;
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    height: 100%;
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.list_toggle_text_selected{
    cursor: pointer;
    font-weight: bold;
    color: #00396a;
    border-bottom: 2px solid #00396a;
    height: 100%;
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.list_title_bar{
    height: 50px;
    width: 100%;
    margin-top: 20px;
    border-bottom: solid 2px #00396a;
}
.rec_content_wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    border-bottom: solid 1px #e7e7e7;
}
.rec_content_left{
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
}
.rec_content_depart{
    font-weight: 500;
    color: #666666;
    padding: 0 20px;
}
.rec_content_title{
    font-size: 20px;
    font-weight: bold;
    padding: 0 20px;
}
.rec_content_right{
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
}
.rec_content_date{
    font-size: 14px;
    color: #666666;
    padding: 0 20px;
}
.rec_content_dday{
    font-size: 20px;
    font-weight: bold;
    color: #00396a;
    padding: 0 20px;
}
.none_text{
    font-weight: bold;
    font-size: 30px;
    margin-top: 30px;
}