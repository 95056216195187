.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 940px;
  justify-content: space-between;
}
.title_wrapper {
  width: 120px;
  text-align: center;
}
.title {
  font-size: 15px;
  color: #00396e;
  font-weight: bold;
}
.site_img {
  width: 100px;
  height: 30px;
}
.icon {
}
span {
  width: 100px;
}
.img_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 828px;
  align-items: center;
}

@media (max-width: 768px) {
  .container {
    width: 100%;
  }

  .img_wrapper {
    width: auto;
    flex-direction: column;
    row-gap: 12px;
    width: 100%;
  }

  .img_wrapper > a {
    width: 100%;
  }

  .site_img {
    width: 100%;
    object-fit: contain;
  }

  .icon {
    display: none;
  }

  .title_wrapper {
    display: none;
  }
}
