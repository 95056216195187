.body {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .body_contents {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    max-width: 1520px;
  }
  .body_top_bg_wrapper {
    margin-top: 125px;
    .body_top_bg {
      height: 200px;
      width: 100vw;
      object-fit: cover;
    }
  }
}
.anniversary {
  position: absolute;
  right: calc((100vw - 1520px) / 2 + 100px);
  top: 820px;
  width: 196px;
  height: 114px;
  object-fit: contain;
}

@media all and (min-width: 768px) and (max-width: 1023px) {
  .body_top_bg {
    display: none;
  }
  .body_top_bg_wrapper {
    width: 100%;
    height: 200px;
    background-color: #d8d8d8;
  }
  .body {
    .body_contents {
      display: flex;
      flex-direction: column;
    }
  }
}

@media all and (max-width: 767px) {
  .body_top_bg {
    display: none;
  }
  .body_top_bg_wrapper {
    width: 100%;
    height: 200px;
    background-color: #d8d8d8;
  }
  .body {
    .body_contents {
      display: flex;
      flex-direction: column;
    }
  }
}
