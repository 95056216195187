.forms-wrapper {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: calc((100vw - 1520px) / 2 + 70px);
  top: 125px;
}
.forms {
  width: 236px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login {
  background-color: #00396a;
  height: 153px;
}
.search {
  background-color: #9e9fa0;
  height: 280px;
}
.forms-sticky {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.forms-title {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-left: 28px;
  margin-bottom: 9px;
}
.input-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 28px;
  width: calc(100% - 56px);
  margin-top: 9px;
}
.input-sticky {
  width: 197px;
  margin-right: 13px;
}
.input {
  width: 160px;
  height: 15px;
  padding: 10px 10px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6f6f6f;
  outline: none;
  border: none;
  margin-bottom: 7px;
}
.input__last {
  margin-top: 10px;
}
.input::placeholder {
}
.input-btn {
  width: 100%;
  height: 45px;
  background-color: #0b0b35;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.forms-footer {
  display: flex;
  flex-direction: row;
}
.checkbox {
  width: 23px;
  height: 23px;
  margin: 0;
}
.forms-footer-text {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  padding: 0 15px;
  height: 18px;
  margin: 2.5px 0;
}
.forms-footer-text__last {
  border-left: 1px solid #ffffff;
}
.forms-btn-wrapper {
  width: 236px;
  height: 125px;
  display: flex;
  flex-direction: row;
}
.forms-btn-wrapper-border {
  border-bottom: 1px solid #dcdcdc;
}
.forms-btn {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.forms-border-right {
  border-right: 1px solid #dcdcdc;
}
.forms-btn__gray {
  background-color: #e7e7e7;
}
.forms-btn__blue {
  background-color: #6183bb;
}
.forms-btn-icon {
  width: 39px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 7px;
  object-fit: contain;
}
.forms-btn-text {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #666666;
  margin-top: 7px;
}
.light {
  color: #ffffff;
}
.anniversary-wrapper {
  width: 100%;
  text-align: center;
  z-index: -999;
}
.anniversary {
  width: 196px;
  height: 114px;
  margin-top: 60px;
  object-fit: contain;
  z-index: -999;
}

@media all and (min-width: 768px) and (max-width: 1023px) {
  .forms-wrapper {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    flex-direction: row;
  }
  .forms-btn-wrapper {
    display: none;
  }
  .input-wrapper {
    display: none;
  }
  .forms {
    height: 50px;
    width: 50%;
  }
  .forms-title {
    margin: 0;
  }
  .forms-sticky {
    align-items: center;
    justify-content: center;
  }
}

@media all and (max-width: 767px) {
  .forms-wrapper {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    flex-direction: row;
  }
  .forms-btn-wrapper {
    display: none;
  }
  .input-wrapper {
    display: none;
  }
  .forms {
    height: 50px;
    width: 50%;
  }
  .forms-title {
    margin: 0;
  }
  .forms-sticky {
    align-items: center;
    justify-content: center;
  }
}
