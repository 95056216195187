.container {
  margin-left: 60px;
  margin-right: 280px;
  display: flex;
  flex-direction: column;
  margin-top: 44px;
  width: 800px;
  .section {
    display: flex;
    flex-direction: column;
    margin-bottom: 45px;
    width: 100%;
    .separation_wrapper {
      border: 1px solid #cccccc;
      width: calc(100% - 3px);
      height: 60px;
      display: flex;
      flex-direction: row;
      cursor: pointer;
      margin-top: 50px;
      .separation_content {
        height: 100%;
        width: calc((100% - 2px) / 3);
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #666666;
        display: flex;
        align-items: center;
        justify-content: center;
        &:nth-child(1) {
          border-right: 1px solid #cccccc;
        }
        &:nth-child(2) {
          border-right: 1px solid #cccccc;
        }
        &:hover {
          background-color: #6083b2;
          color: #ffffff;
        }
      }
      .separation_selected {
        height: 100%;
        width: calc((100% - 2px) / 3);
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        background-color: #6083b2;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .section_title {
      font-weight: bold;
      margin-left: 20px;
      margin-top: 40px;
    }
    .title_normal {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }
    .datalist_wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 40px;
      justify-content: flex-start;
      column-gap: calc(80px / 3);
    }
    .datalist_wrapper2 {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 20px;
      justify-content: flex-start;
      column-gap: calc(80px / 3);
      padding: 20px 0;
    }
    .datalist_wrapper3 {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 20px;
      justify-content: flex-start;
      column-gap: calc(80px / 3);
      padding: 20px 0;
    }
    .top_type_wrapper {
      display: flex;
      flex-direction: row;
      width: calc(100% - 3px);
      border: 1px solid #dcdcdc;
      cursor: pointer;
      margin-bottom: 55px;
      .top_type_content {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #666666;
        width: 50%;
        padding: 18px 0;
        text-align: center;
        &:nth-child(1) {
          border-right: 1px solid #dcdcdc;
        }
        &:hover {
          background-color: #6083b2;
          color: #ffffff;
        }
      }
      .top_type_selected {
        background-color: #6083b2;
        color: #ffffff;
      }
    }
  }
}

.leaflet_wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: calc(100% / 4 - 20px);
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 10px;
  transition: all 0.3s;
  &:hover {
    border: 1px solid #0a5a7a;
    box-shadow: 1px 0 20px rgba(0, 0, 0, 0.15);
  }
  .datacontent_title {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
    background-color: #d9ebf2;
    border-bottom: 1px solid #d2d2d2;
    height: 40px;
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .datacontent_title_big {
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #575757;
    border: 1px solid #d1d1d1;
    height: 40px;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    margin: 15px;
    margin-top: 0;
    white-space: pre;
    @media all and (max-width: 767px) {
      font-size: 10px;
      white-space: inherit;
      margin: 0;
      padding: 8px 4px;
    }
  }
  .datacontent_title_big:hover {
    color: #ffffff;
    background-color: #486e99;
  }
  .datacontent_img_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
  }
  .datacontent_img_wrapper_big {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
  }
  .datacontent_img {
    width: 160px;
    height: 200px;
    background-color: #ffffff;
    border: 1px solid #d1d1d1;
  }
  .datacontent_img_big {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    object-fit: cover;
    border: 1px solid #d1d1d1;
  }
  .datacontent_img_big_lefleat {
    width: calc(100% + 6px);
    height: 100%;
    background-color: #ffffff;
    object-fit: cover;
  }
  .datacontent_download_button_wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    .datacontent_download_button {
      color: #666666;
      border-radius: 9px;
      border: solid 1px #ededed;
      text-align: center;
      padding: 10px;
      width: 60px;
      display: flex;
      justify-content: space-around;
      font-size: 10px;
      cursor: pointer;
      &:hover {
        background-color: #0a5a7a;
        color: #ffffff;
        & > svg {
          fill: #ffffff;
        }
        .datacontent_download_img_white {
          display: block;
        }
        .datacontent_download_img_black {
          display: none;
        }
      }

      .datacontent_download_img_black {
        width: 20px;
        height: 20px;
        object-fit: contain;
      }
      .datacontent_download_img_white {
        width: 20px;
        height: 20px;
        object-fit: contain;
        display: none;
      }
    }
  }
}

.academicfile_wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: calc(100% / 3 - 20px);
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 10px;
  transition: all 0.3s;
  &:hover {
    border: 1px solid #0a5a7a;
    box-shadow: 1px 0 20px rgba(0, 0, 0, 0.15);
  }
  .datacontent_title {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
    background-color: #d9ebf2;
    border-bottom: 1px solid #d2d2d2;
    height: 40px;
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .datacontent_title_big {
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #575757;
    border: 1px solid #d1d1d1;
    height: 40px;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    margin: 15px;
    margin-top: 0;
    white-space: pre;
    @media all and (max-width: 767px) {
      font-size: 10px;
      white-space: inherit;
      margin: 0;
    }
  }
  .datacontent_title_big:hover {
    color: #ffffff;
    background-color: #486e99;
  }
  .datacontent_img_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
  }
  .datacontent_img_wrapper_big {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
  }
  .datacontent_img {
    width: 160px;
    height: 200px;
    background-color: #ffffff;
    border: 1px solid #d1d1d1;
  }
  .datacontent_img_big {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    object-fit: cover;
    border: 1px solid #d1d1d1;
  }
  .datacontent_download_button_wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    .datacontent_download_button {
      color: #666666;
      border-radius: 9px;
      border: solid 1px #ededed;
      text-align: center;
      padding: 10px;
      width: 60px;
      display: flex;
      justify-content: space-around;
      font-size: 10px;
      cursor: pointer;

      &:hover {
        background-color: #0a5a7a;
        color: #ffffff;
        & > svg {
          fill: #ffffff;
        }
        .datacontent_download_img_white {
          display: block;
        }
        .datacontent_download_img_black {
          display: none;
        }
      }

      .datacontent_download_img_black {
        width: 20px;
        height: 20px;
        object-fit: contain;
      }
      .datacontent_download_img_white {
        width: 20px;
        height: 20px;
        object-fit: contain;
        display: none;
      }
    }
  }
}

.datacontent_wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: calc(100% / 4 - 20px);
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 10px;
  transition: all 0.3s;
  &:hover {
    border: 1px solid #0a5a7a;
    box-shadow: 1px 0 20px rgba(0, 0, 0, 0.15);
    .datacontent_title {
      margin: 0 10px;
      padding: 10px 0;
    }
    .datacontent_download_button_wrapper {
      .datacontent_download_button {
        border-bottom-color: transparent;
        border-left-color: transparent;
        border-right-color: transparent;
      }
    }
  }
  .datacontent_title {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
    border-bottom: 2px solid #9f9f9f;
    height: 40px;
    padding: 10px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;
  }
  .datacontent_title_big {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
    border-bottom: 5px solid #6083b2;
    height: 40px;
    padding: 10px 0;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;
  }
  .datacontent_title_big:hover {
    color: #ffffff;
    background-color: #6083b2;
  }
  .datacontent_img_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
  }
  .datacontent_img_wrapper_big {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .datacontent_img {
    width: 160px;
    height: 200px;
    background-color: #ffffff;
  }
  .datacontent_img_big {
    width: 200px;
    height: 240px;
    background-color: #ffffff;
  }
  .datacontent_download_button_wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    .datacontent_download_button {
      color: #666666;
      border-radius: 9px;
      border: solid 1px #ededed;
      text-align: center;
      padding: 10px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      column-gap: 12px;
      cursor: pointer;

      &:hover {
        background-color: #0a5a7a;
        color: #ffffff;
        & > svg {
          fill: #ffffff;
        }
        .datacontent_download_img_white {
          display: block;
        }
        .datacontent_download_img_black {
          display: none;
        }
      }

      .datacontent_download_img_black {
        width: 20px;
        height: 20px;
        object-fit: contain;
      }
      .datacontent_download_img_white {
        width: 20px;
        height: 20px;
        object-fit: contain;
        display: none;
      }
    }
  }
}

@media all and (min-width: 768px) and (max-width: 1023px) {
}

@media all and (max-width: 767px) {
}

.pagenumberlist {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;
  align-items: center;
  .previous {
    width: 0px;
    height: 0px;
    border-top: 7.5px solid transparent;
    border-right: 15px solid #525252;
    border-bottom: 7.5px solid transparent;
    margin-right: 8px;
  }
  .next {
    width: 0px;
    height: 0px;
    border-top: 7.5px solid transparent;
    border-left: 15px solid #525252;
    border-bottom: 7.5px solid transparent;
    margin-left: 8px;
  }
}

.pagenum_wrapper {
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: rgba(72, 110, 153, 0.1);
  }
  .pagenum {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #616161;
    cursor: pointer;
  }
}
.pagenum_selected {
  width: 40px;
  height: 40px;
  background-color: rgba(72, 110, 153, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  .pagenum {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #486e99;
    cursor: pointer;
  }
}
@media all and (min-width: 768px) and (max-width: 1023px) {
  .container {
    width: calc(100% - 80px);
    padding: 0 40px;
    margin: 0;
    margin-top: 50px;
  }
}

@media all and (max-width: 767px) {
  .container {
    width: calc(100% - 40px);
    padding: 0 20px;
    margin: 0;
    margin-top: 50px;
    .section {
      .datalist_wrapper {
        .datacontent_wrapper {
          width: calc(100% / 2);
          .datacontent_img {
            padding: 0;
          }
        }
      }
    }
  }
}
