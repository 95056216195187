.container{
    margin-left: 60px;
    margin-right: 280px;
    display: flex;
    flex-direction: column;
    margin-top: 44px;
    width: 800px;
    .greeting_top_wrapper{
        margin-bottom: 40px;
        padding-bottom: 30px;
        border-bottom: 1px solid #dcdcdc;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .greeting_top_img{
            width: 60%;
        }
    }
    .section{
        display: flex;
        flex-direction: column;
        margin-bottom: 45px;
        width: 100%;
        align-items: center;
        .history_wrapper{
            display: flex;
            flex-direction: column;
            position: relative;
            align-items: center;
            width: 100%;
            .divider{
                width: 2px;
                color: #ececec;
                height: 100%;
                position: absolute;
            }
            .year_wrapper{
                display: flex;
                flex-direction: column;
                position: relative;
                align-items: center;
                width: 100%;
                .big_round{
                    font-weight: bold;
                    font-size: 30px;
                    background-image: url("./round.svg");
                    width: 108px;
                    height: 108px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    color: white;
                    z-index: 99;
                    margin-bottom: 40px;
                    .two_line_text{
                        font-size: 24px;
                    }
                    .two_line_flow{
                        height: 10px;
                        display: flex;
                        align-items: center;
                        font-size: 24px;
                    }
                }
                .row_left{
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 30px;
                    width: 100%;
                    justify-content: flex-end;
                    margin-right: calc(100% - 20px);
                    .row_text{
                        font-weight: 500;
                        color: #666666;
                        margin-right: 10px;
                    }
                    .small_round{
    
                    }
                }
                .row_right{
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 30px;
                    width: 100%;
                    justify-content: flex-start;
                    margin-left: calc(100% - 20px);
                    .row_text{
                        font-weight: 500;
                        color: #666666;
                        margin-left: 10px;
                    }
                    .small_round{
    
                    }
                }
            }
            
        }
    }
}

@media all and (min-width:768px) and (max-width:1023px){
    .container{
        width: calc(100% - 80px);
        padding: 0 40px;
        margin: 0;
        margin-top: 50px;
    }
}

@media all and (max-width:767px){
    .container{
        width: calc(100% - 40px);
        padding: 0 20px;
        margin: 0;
        margin-top: 50px;
        .section{
            
            .history_wrapper{
                .history_text_wrapper{
                    margin-left: 10px;
                    .history_text_1{
                        font-size: 14px;
                    }
                    .history_text_2{
                        font-size: 14px;
                    }
                    .history_text_3{
                        font-size: 14px;
                    }
                }
                .history_bar{
                    .bar_text_1{
                        font-size: 12px;
                        padding: 0 12px;
                    }
                    .bar_text_2{
                        font-size: 12px;
                        padding: 0 12px;
                    }
                    .bar_text_3{
                        font-size: 12px;
                        padding: 0 12px;
                    }
                }
            }
        }
    }
}