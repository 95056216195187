.container {
  margin-left: 60px;
  margin-right: 280px;
  display: flex;
  flex-direction: column;
  margin-top: 44px;
  width: 800px;
  .section {
    display: flex;
    flex-direction: column;
    margin-bottom: 45px;
    width: 100%;
    .text_light {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: #666666;
    }
    .extraction_img {
      margin-left: 50px;
      width: calc(100% - 50px);
      object-fit: contain;
    }
    .text_blue {
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.73;
      letter-spacing: normal;
      color: #64a4e3;
      margin-left: 50px;
      margin-bottom: 16px;
    }
    .sample_handling_1 {
      margin-left: 50px;
      width: calc(100% - 50px);
      object-fit: contain;
    }
    .extraction_last_wrapper {
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      margin-left: 50px;
      .extraction_last {
      }
      .extraction_last_text {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #666666;
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 10px;
      }
    }
    .mixing_title {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      padding-bottom: 18px;
      border-bottom: 1px solid #dcdcdc;
      margin-left: 50px;
      width: calc(100% - 50px);
      margin-top: 15px;
      margin-bottom: 20px;
    }
    .mixing_content_wrapper {
      display: flex;
      flex-direction: row;
      .mixing_img {
        width: 80px;
        height: 137px;
        object-fit: contain;
        margin-left: 50px;
      }
      .mixing_text_wrapper {
        display: flex;
        flex-direction: column;
        margin-left: 38px;
        .mixing_text_top {
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.64;
          letter-spacing: normal;
          color: #666666;
          margin-bottom: 16px;
        }
        .mixing_text_bot {
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.73;
          letter-spacing: normal;
          color: #64a4e3;
        }
      }
    }
    .tips_wrapper {
      margin-left: 50px;
      width: calc(100% - 90px);
      background-color: #f6f6f6;
      display: flex;
      flex-direction: column;
      padding: 20px;
      .tips_img {
        width: 696px;
        height: 157px;
        object-fit: contain;
      }
      .tips_bold {
        margin: 20px 0;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: normal;
        color: #000000;
      }
      .tips_text {
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: normal;
        color: #303030;
      }
      .tips_text_bold {
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.73;
        letter-spacing: normal;
        color: #666666;
      }
      .tips_items_wrapper {
        width: calc(100% - 160px);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0 80px;
        .tips_item {
          width: calc(100% / 4);
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.73;
          letter-spacing: normal;
          color: #000000;
          margin-top: 7px;
          &:nth-child(7) {
            width: 50%;
          }
        }
      }
    }
    .section_num_wrapper {
      display: flex;
      flex-direction: column;
      .section_num_content {
        margin-top: 30px;
        .section_num_title {
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000000;
        }
        .section_num_text {
          font-size: 15px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.73;
          letter-spacing: normal;
          color: #666666;
          margin-top: 18px;
        }
      }
    }
    .section_title {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      margin-bottom: 15px;
      margin-top: 60px;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    .text_normal_500 {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }
    .sub_wrapper {
      margin-top: 35px;
      .sub_tube_wrapper {
        display: flex;
        margin-left: 50px;
        width: calc(100% - 50px);
        justify-content: center;
      }
      &:nth-child(2) {
        margin-bottom: 60px;
      }
      .sub_text {
        margin-left: 30px;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: normal;
        color: #303030;
      }
      .sub_text_wrapper {
        display: flex;
        flex-direction: row;
        .sub_text {
          margin-left: 30px;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.8;
          letter-spacing: normal;
          color: #303030;
        }
        .sub_img {
        }
      }
      .sub_num_table {
        margin-left: 50px;
      }
      .sub_num_wrapper {
        display: flex;
        flex-direction: row;
        margin-left: 30px;
        width: calc(100% - 30px);
        margin-top: 25px;
        .rect {
          width: 10px;
          height: 20px;
          background-color: #6083b2;
          margin-right: 10px;
        }
        .sub_num_title {
          width: calc(100% - 20px);
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000000;
          padding-bottom: 18px;
          border-bottom: 1px solid #dcdcdc;
          margin-bottom: 20px;
        }
      }
      .sub_num_text {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: normal;
        color: #666666;
        margin-left: 50px;
        .emphasis {
          color: #000000;
        }
      }
    }
  }
}
.sub_title_wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  .rect {
    width: 20px;
    height: 20px;
    background-color: #6083b2;
    margin-right: 10px;
  }
  .sub_title_text {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6083b2;
    padding-bottom: 18px;
    border-bottom: 1px solid #dcdcdc;
    margin-bottom: 17px;
    width: calc(100% - 30px);
  }
}

.mg_lft_50 {
  margin-left: 50px;
}

.sub_num_wrapper_bot {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 25px;
  .rect {
    width: 10px;
    height: 20px;
    background-color: #6083b2;
    margin-right: 10px;
  }
  .sub_num_title {
    width: calc(100% - 20px);
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    padding-bottom: 18px;
    border-bottom: 1px solid #dcdcdc;
    margin-bottom: 20px;
  }
}

.sub_num_text_bot {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: normal;
  color: #666666;
  margin-left: 20px;
  .emphasis {
    color: #000000;
  }
}

.sample_handling_bot {
  margin-top: 20px;
}

@media all and (min-width: 768px) and (max-width: 1023px) {
  .container {
    width: calc(100% - 80px);
    padding: 0 40px;
    margin: 0;
    margin-top: 50px;
  }
}

@media all and (max-width: 767px) {
  .container {
    width: calc(100% - 40px);
    padding: 0 20px;
    margin: 0;
    margin-top: 50px;
    .section {
      .section_title {
        margin-top: 30px;
      }
      .sub_wrapper {
        .sub_num_text {
          margin-left: 0;
          font-size: 13px;
        }
        .sub_tube_wrapper {
          margin-left: 0;
          width: 100%;
          .sub_tube_img {
            width: 100%;
          }
        }
      }
      .mixing_title {
        margin-left: 0;
      }
      .mixing_content_wrapper {
        .mixing_img {
          margin-left: 0;
        }
        .mixing_text_wrapper {
          .mixing_text_top {
            font-size: 13px;
          }
          .mixing_text_bot {
            font-size: 13px;
          }
        }
      }
    }
  }
  .mobile_none {
    display: none;
  }
}
