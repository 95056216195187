.popupContainer {
  position: fixed;
  top: 20px;
  left: 20px;
  display: flex;
  z-index: 999999;
}

.main {
  width: 100vw;
}
.body {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.body_upper {
  display: flex;
  flex-direction: row;
  height: 700px;
  width: 100%;
  max-width: 1520px;
  justify-content: center;
  margin-top: 100px;
}
.body_wrapper {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.body_slide {
  max-width: 1520px;
  width: calc(1520px - 390px);
  height: 100%;
}
.body_row {
  display: flex;
  flex-direction: row;
}
.body_slide_box {
  width: 100%;
  display: flex;
}
.body_slide_bar {
  width: 50px;
  background-color: #ddd;
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  padding: 55px 10px;
  box-sizing: border-box;
  text-align: center;
}
.body_slide_card {
  width: 0px;
  background-color: #ccc;
  overflow: hidden;
  transition-duration: 2s;
  height: 700px;
  .card_container {
    background-size: cover;
    min-width: 1080px;
    width: 100%;
    height: 100%;
    padding-top: 176px;
    padding-left: 20%;
    box-sizing: border-box;
    .white_box {
      width: 650px;
      height: 450px;
      background-color: rgba(255, 255, 255, 0.45);
      text-align: right;
      display: flex;
      flex-direction: column;
      padding-right: 50px;
      justify-content: center;
      .sub_title {
        font-size: 22px;
        font-weight: bold;
        height: 33px;
      }
      .title {
        font-size: 44px;
        font-weight: bold;
        line-height: 65px;
      }
      .name {
        margin-top: 60px;
        font-size: 25px;
        font-weight: 500;
        line-height: 37px;
        height: 37px;
      }
      .description {
        font-size: 16px;
        margin-top: 24px;
        line-height: 25px;
        font-weight: 600;
      }
      .more {
        margin-top: 25px;
        width: 122px;
        height: 50px;
        border: solid 1px #ffffff;
        background-color: rgba(255, 255, 255, 0.4);
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        line-height: 50px;
        align-self: flex-end;
      }
    }
  }
}
.body_slide_card.active {
  width: calc(100% - 150px);
  background-color: #ccc;
  transition-duration: 2s;
}
.slide_bar_1 {
  background-color: #02649b;
}
.slide_bar_2 {
  background-color: #5aa2ec;
}
.slide_bar_3 {
  background-color: #006eb3;
}
.body_lower {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.body_list {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 999;
  position: relative;
  top: -60px;
  background-image: url("./shadow.svg");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: (174px * 5.5);
}
.list_content {
  width: 228px;
  height: 268px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.list_content__gray {
  background-color: #e7e7e7;
}
.list_content__white {
  background-color: #ffffff;
}

.list_content_img_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  margin-bottom: 30px;
}
.list_text {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-top: 10px;
}
.body_sites {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 84px;
  width: 940px;
}
.site_text {
  height: 28px;
  font-size: 19px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00396e;
  margin-right: 30px;
}
.body_list {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 999;
  position: relative;
  top: -48px;
  height: 298px;
  align-items: center;
}
.list_content {
  width: 228px;
  height: 268px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.list_content__gray {
  background-color: #e7e7e7;
}
.list_content__white {
  background-color: #ffffff;
}

.list_content_img_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  margin-bottom: 30px;
}
.list_text {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-top: 10px;
}
.body_site {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 22px;
  margin-bottom: 84px;
  width: 1140px;
}
.site_text {
  height: 28px;
  font-size: 19px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00396e;
  margin-right: 30px;
}

@media all and (min-width: 768px) and (max-width: 1023px) {
  .body_upper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto !important;
  }
  .body_slide {
    width: 100vw;
    height: 545px;
  }
  .body_list {
    top: 0;
    height: 164px;
  }
  .body_slide_box {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .body_slide_bar {
    width: 100%;
    font-size: 20px;
    color: #fff;
    font-weight: 700;
    padding: 8px 50px;
    box-sizing: border-box;
    text-align: left;
  }
  .body_site {
    display: none;
  }

  .body_slide_card {
    height: 0px;
    width: 100%;
    background-color: #ccc;
    overflow: hidden;
    box-sizing: border-box;
    transition-duration: 2s;
    .card_container {
      background-size: cover;
      min-width: 1080px;
      width: 100%;
      height: 100%;
      padding-top: 56px;
      padding-left: 30%;
      box-sizing: border-box;
      .white_box {
        width: 390px;
        height: 232px;
        background-color: rgba(255, 255, 255, 0.45);
        text-align: right;
        padding-top: 38px;
        display: flex;
        flex-direction: column;
        padding-right: 50px;
        .sub_title {
          font-size: 10px;
          font-weight: bold;
          height: 15px;
        }
        .title {
          font-size: 16px;
          font-weight: bold;
          line-height: 24px;
        }
        .name {
          margin-top: 15px;
          font-size: 18px;
          font-weight: 500;
          line-height: 27px;
          height: 27px;
        }
        .description {
          font-size: 10px;
          margin-top: 2px;
          line-height: 14px;
        }
        .more {
          margin-top: 25px;
          width: 83px;
          height: 30px;
          border: solid 1px #ffffff;
          background-color: rgba(255, 255, 255, 0.4);
          text-align: center;
          font-size: 13px;
          font-weight: bold;
          line-height: 30px;
          align-self: flex-end;
        }
      }
    }
  }
  .body_slide_card.active {
    width: 100%;
    height: 411px;
    background-color: #ccc;
    transition-duration: 2s;
  }
  .input {
    display: none;
  }
}

@media all and (max-width: 767px) {
  .body_upper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto !important;
  }
  .body_slide {
    width: 100vw;
  }
  .body_list {
    top: 0;
    height: 164px;
  }
  .body_slide_box {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .body_slide_bar {
    width: 100%;
    font-size: 20px;
    color: #fff;
    font-weight: 700;
    padding: 8px 50px;
    box-sizing: border-box;
    text-align: left;
  }
  .body_site {
    display: none;
  }

  .body_sites {
    width: 100%;
  }

  .body_row {
    flex-direction: column;
    width: 100%;
  }

  .body_slide_card {
    height: 0px;
    width: 100%;
    background-color: #ccc;
    overflow: hidden;
    box-sizing: border-box;
    transition-duration: 2s;
    .card_container {
      background-size: cover;
      min-width: 100px;
      width: 100%;
      height: 232px;
      padding-top: 56px;
      padding-left: 20px;
      padding-right: 20px;
      box-sizing: border-box;
      .white_box {
        width: 275px;
        height: 232px;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.45);
        text-align: right;
        padding-top: 38px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding-right: 19px;
        .sub_title {
          font-size: 10px;
          font-weight: bold;
          height: 15px;
        }
        .title {
          font-size: 16px;
          font-weight: bold;
          line-height: 24px;
        }
        .name {
          margin-top: 15px;
          font-size: 18px;
          font-weight: 500;
          line-height: 27px;
          height: 27px;
        }
        .description {
          font-size: 10px;
          margin-top: 2px;
          line-height: 14px;
        }
        .more {
          margin-top: 25px;
          width: 83px;
          height: 30px;
          border: solid 1px #ffffff;
          background-color: rgba(255, 255, 255, 0.4);
          text-align: center;
          font-size: 13px;
          font-weight: bold;
          line-height: 30px;
          align-self: flex-end;
        }
      }
    }
  }
  .body_slide_card.active {
    width: 100%;
    height: 232px;
    background-color: #ccc;
    transition-duration: 2s;
  }
  .input {
    display: none;
  }
}
