.container {
  margin-left: 60px;
  margin-right: 280px;
  display: flex;
  flex-direction: column;
  margin-top: 44px;
  width: 800px;
  .greeting_top_wrapper {
    margin-bottom: 40px;
    padding-bottom: 30px;
    border-bottom: 1px solid #dcdcdc;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .greeting_top_img {
      width: 60%;
    }
  }
  .section {
    margin-bottom: 45px;
    width: 100%;
    .org_table_wrapper {
      margin-top: 30px;
      width: 100%;
      border-top: 1px solid #dcdcdc;
      display: flex;
      flex-direction: column;
      align-items: center;
      .org_table_title {
        margin-top: 18px;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #00396e;
      }
      .org_table_sticky {
        background-color: rgba(35, 76, 109, 0.1);
        display: flex;
        flex-direction: row;
        padding: 23px 67px;
        width: calc(100% - 134px);
        margin-top: 20px;
        justify-content: center;
        .org_table_content {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: calc(100% / 4);
          .table_title {
            padding-bottom: 20px;
            border-bottom: 1px solid #ffffff;
            margin-bottom: 13px;
            font-size: 17px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #595959;
            width: 100%;
            text-align: center;
          }
          .table_text {
            font-size: 17px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #2b2b2b;
            margin-bottom: 9px;
          }
        }
      }
    }
    .greeting_title {
      font-size: 30px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }
    .org_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: 35px;
      .org_ceo {
      }
      .org_oval {
      }
      .org_content_wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        .org_content_line {
          display: flex;
          flex-direction: column;
          width: 173px;
          .org_top_content {
            background-image: url("./organization.svg");
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;
            justify-content: center;
            width: 173px;
            height: 82px;
            object-fit: contain;
            .org_top_title {
              margin-left: 52px;
              font-size: 19px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #000000;
            }
            .org_top_eng {
              margin-left: 52px;
              font-size: 12px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #999999;
            }
            .org_top_small {
              margin-left: 52px;
              font-size: 10px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #515151;
            }
          }
          .org_content {
            width: 123px;
            padding: 25px 25px;
            border: 1px solid #d4d4d4;
            margin-bottom: 10px;
            .org_content_title {
              font-size: 16px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #000000;
            }
            .org_content_class {
              font-size: 12px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #999999;
            }
            .org_content_eng {
              font-size: 10px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #515151;
              padding-bottom: 10px;
              border-bottom: 1px solid #2d4c6a;
              margin-bottom: 10px;
              margin-top: 2px;
            }
            .org_content_team {
              font-size: 12px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.6;
              letter-spacing: normal;
              color: #515151;
            }
          }
        }
      }
    }
  }
}

@media all and (min-width: 768px) and (max-width: 1023px) {
  .container {
    width: calc(100% - 80px);
    padding: 0 40px;
    margin: 0;
    margin-top: 50px;
  }
}

@media all and (max-width: 767px) {
  .container {
    width: calc(100% - 40px);
    padding: 0 20px;
    margin: 0;
    margin-top: 50px;
    .section {
      .org_table_wrapper {
        margin-top: 30px;
        .org_table_sticky {
          padding: 15px;
          width: calc(100% - 30px);
          .org_table_content {
            width: calc(100% / 3);
            &:nth-child(4) {
              display: none;
            }
            .table_text {
              font-size: 11px;
            }
          }
        }
      }
      .org_wrapper {
        align-items: center;
        .org_ceo {
          width: 40%;
          height: auto;
        }
        .org_oval {
          width: 100%;
          height: auto;
        }
        .org_content_wrapper {
          .org_content_line {
            width: auto;
            max-width: 100px;
            &:nth-child(2) {
              margin: 0 10px;
            }
            .org_top_content {
              width: auto;
              background-size: auto;
              background-repeat: no-repeat;
              .org_top_title {
                font-size: 12px;
                margin-left: 20px;
              }
              .org_top_eng {
                font-size: 8px;
                margin-left: 20px;
              }
              .org_top_small {
                font-size: 6px;
                margin-left: 20px;
              }
            }
            .org_content {
              width: auto;
              padding: 5px;
              .org_content_title {
                font-size: 12px;
              }
              .org_content_class {
                font-size: 10px;
              }
              .org_content_eng {
                font-size: 8px;
              }
              .org_content_team {
              }
            }
          }
        }
      }
    }
  }
}
