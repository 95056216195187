.container{
    margin-left: 60px;
    margin-right: 280px;
    display: flex;
    flex-direction: column;
    margin-top: 44px;
    width: 800px;
    .section{
        display: flex;
        flex-direction: column;
        margin-bottom: 45px;
        .process_title{
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #000000;
            margin-bottom: 20px;
        }
        .process_img_wrapper{
            display: flex;
            flex-direction: row;
            width: 100%;
            .process_img{
                margin: 0 calc(50px / 8);
                &:nth-child(1){
                    margin-left: 0;
                }
                &:nth-last-child(1){
                    margin-right: 0;
                }
            }
        }
        .htw_img{
            margin: 35px 0;
        }
        .ref_text{
        
            font-size: 14px;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #7b7b7b;
        }
        .how_to_write{
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            .rect{
                width: 20px;
                height: 20px;
                background-color: #64a4e3;
            }
            .htw_title{
            
                font-size: 16px;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #000000;
                font-weight: 500;
                margin: 0 10px;
            }
            .htw_content{
            
                font-size: 14px;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #64a4e3;
                font-weight: 500;
            }
        }
        .process_bold_text{
        
            font-size: 16px;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #000000;
            font-weight: bold;
        }
        .method_table_wrapper{
            margin-top: 25px;
            width: calc(100% - 2px);
            border: 1px solid #dcdcdc;
            display: flex;
            flex-direction: column;
            .method_table_content{
                border-bottom: 1px solid #dcdcdc;
                width: 100%;
                height: 54px;
                display: flex;
                flex-direction: row;
                &:nth-last-child(1){
                    border-bottom: none;
                }
                .method_table_title{
                
                    font-size: 16px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #333333;
                    background-color: #e3eaf5;
                    width: 110px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .method_table_text{
                
                    font-size: 14px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.57;
                    letter-spacing: normal;
                    color: #666666;
                    width: calc(100% - 110px);
                    display: flex;
                    align-items: center;
                    margin-left: 16px;
                }
            }
        }
        .collection_table_wrapper{
            display: flex;
            width: calc(100% - 2px);
            flex-direction: column;
            border: 1px solid #dcdcdc;
            margin-bottom: 10px;
            .collection_table_content{
                display: flex;
                flex-direction: row;
                height: 75px;
                width: 100%;
                border-bottom: 1px solid #dcdcdc;
                &:nth-last-child(1){
                    border-bottom: 0;
                }
                .collection_table_title{
                    background-color: #e3eaf5;
                    width: 110px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                
                    font-size: 16px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #333333;
                }
                .collection_table_text{
                
                    font-size: 14px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.57;
                    letter-spacing: normal;
                    color: #666666;
                    width: calc(100% - 110px);
                    display: flex;
                    align-items: center;
                    margin-left: 16px;
                }
            }
        }
        .text_normal{
        
            font-size: 15px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.8;
            letter-spacing: normal;
            color: #303030;
        }
        &:nth-child(7){
            margin-bottom: 150px;
        }
    }
}
@media all and (min-width:768px) and (max-width:1023px){
    .container{
        width: calc(100% - 80px);
        padding: 0 40px;
        margin: 0;
        margin-top: 50px;
    }
}

@media all and (max-width:767px){
    .container{
        width: calc(100% - 40px);
        padding: 0 20px;
        margin: 0;
        margin-top: 50px;
    }
}