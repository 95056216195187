.container{
    margin-left: 60px;
    margin-right: 280px;
    display: flex;
    flex-direction: column;
    margin-top: 44px;
    width: 800px;
    .section{
        display: flex;
        flex-direction: column;
        margin-bottom: 45px;
        width: 100%;
        .map_img_wrapper{
            margin: 35px 0;
        }
        .car_content{
            display: flex;
            flex-direction: column;
            border-top: 1px solid #dcdcdc;
            margin-bottom: 25px;
            .car_content_title{
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.2px;
                color: #000000;
                padding: 18px 0;
            }
            .car_content_text{
                font-size: 15px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.17px;
                color: #666666;
            }
        }
        .contact_title{
            font-size: 25px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.28px;
            color: #000000;
        }
        .subway_content_wrapper{
            border-top: 1px solid #dcdcdc;
            width: 100%;
            display: flex;
            flex-direction: column;
            padding-top: 20px;
            .subway_content{
                display: flex;
                flex-direction: row;
                margin-bottom: 35px;
                .content_sticky{
                    display: flex;
                    flex-direction: row;
                    .subway_text{
                        font-size: 15px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: 0.17px;
                        color: #666666;
                    }
                }
            }
        }
        .contact_section_wrapper{
            display: flex;
            height: 50px;
            align-items: center;
            margin-top: 21px;
            margin-bottom: 21px;
            .contact_icon{

            }
            .contact_section_text{
                font-size: 22px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #486e99;
                margin-left: 10px;
            }
        }
        .title_normal{
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #000000;
        }
        .address_wrapper{
            padding: 14px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-top: 1px solid #dcdcdc;
            border-bottom: 1px solid #dcdcdc;
            margin-bottom: 40px;
            .address_content{
                display: flex;
                flex-direction: row;
                &:nth-child(2){
                    margin: 11px 0;
                }
                .address_title{
                    font-size: 18px;
                    font-weight: bold;
                    width: 100px;
                    &:nth-child(1){
                        letter-spacing: 0.2px;
                    }
                    &:nth-child(2){
                        letter-spacing: 4.4px;
                    }
                    &:nth-child(3){
                        letter-spacing: 6.4px;
                    }
                }
                .address_text{
                    font-size: 18px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #000000;
                    margin-left: 16px;
                    width: 270px;
                }
            }
        }
    }
}

.icons{
    margin: 0 8px;
    height: 22px;
}
@media all and (min-width:768px) and (max-width:1023px){
    .container{
        width: calc(100% - 80px);
        padding: 0 40px;
        margin: 0;
        margin-top: 50px;
    }
}

@media all and (max-width:767px){
    .container{
        width: calc(100% - 40px);
        padding: 0 20px;
        margin: 0;
        margin-top: 50px;
        .section{
            .map_img_wrapper{
                .google_map{
                    width: 100%;
                }
            }
            .address_wrapper{
                width: 100%;
                display: flex;
                flex-direction: column;
                
            }
            .subway_content_wrapper{
                .subway_content{
                    display: flex;
                    flex-direction: column;
                    .content_sticky{
                        margin-bottom: 10px;
                        .icons{
                            width: auto;
                        }
                    }
                    
                }
            }
        }
    }
}

.asw{
    display: flex;
    flex-direction: row;
    .address_sticky_title{
        font-size: 18px;
        font-weight: bold;
        margin-right: 15px;
        width: 100px;
        text-align: left;
    }
    .address_sticky{
        display: flex;
        flex-direction: column;
        .address_content{
            display: flex;
            flex-direction: row;
            .address_title{
                font-size: 16px;
                color: #494949;
                
            }
            .address_text{
                font-size: 16px;
            }
        }
    }
}