.container{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 125px;
}
.sticky_container{
    max-width: 960px;
    display: flex;
    flex-direction: column;
}
.title{
    font-size: 40px;
    font-weight: bold;
    color: #00396a;
    margin-top: 95px;
    margin-bottom: 70px;
}
.sitemap_table{
    border-top: 1px solid #e0e0e0;
    padding: 30px 0;
    display: flex;
    flex-direction: row;
}
.table_title{
    font-size: 24px;
    font-weight: bold;
    width: 130px;
    margin-top: 20px;
}
.table_content_wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 840px;
}
.table_content{
    width: 200px;
    margin-top: 30px;
}
.table_content_title{
    font-size: 18px;
    font-weight: bold;
    color: #444444;
    margin-left: 10px;
    margin-bottom: 5px;
}
.table_content_text{
    margin-top: 15px;
    margin-left: 10px;
    color: #6b6b6b;
    font-size: 16px;
}