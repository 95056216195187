.container{
    margin-left: 60px;
    margin-right: 280px;
    display: flex;
    flex-direction: column;
    margin-top: 44px;
    width: 800px;
    .section{
        display: flex;
        flex-direction: column;
        margin-bottom: 45px;
        width: 100%;
        .process_wrapper{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .process_text{
                color: #666666;
                font-size: 18px;
                font-weight: 500;
            }
        }
        .notice_wrapper{
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            .notice_title{
                font-size: 20px;
                font-weight: bold;
            }
            .notice_button{
                width: 260px;
                height: 50px;
                object-fit: contain;
                cursor: pointer;
                border-radius: 6px;
                background-color: #00396e;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                font-weight: bold;
                color: #ffffff;
            }
        }
        .rec_process{
            width: 100%;
        }
        .rec_wrapper{
            display: flex;
            flex-direction: row;
            .rec_text{
                font-size: 18px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #666666;     
                width: 60%;        
                .emphasis{
                    color: #000000;
                } 
            }
            .rec_text_wrapper{
                display: flex;
                flex-direction: column;
                width: 40%;
                align-items: flex-end;
                margin-right: 40px;
                .rec_text_2{
                    font-size: 18px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #000000;
                    margin-top: 35px;
                }
                .rec_text_3{
                    font-size: 20px;
                    font-weight: 900;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #000000;
                    margin-top: 10px;
                }
            }
        }
        .title_normal{
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #000000;
        }
        .section_text{
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #666666;
        }
        .title_text_wrapper{
            display: flex;
            width: 100%;
            flex-direction: row;
            justify-content: center;
            margin-top: 50px;
            margin-bottom: 27px;
            .title_text{
                font-size: 28px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #676767;
                margin-top: 30px;
                .emphasis{
                    color: #6083b2;
                }
            }
            .quot{
                font-size: 50px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #e1e1e1;
                text-align: center;
            }
        }
        
        .img{
            width: 763px;
            text-align: center;
            height: 433px;
            object-fit: contain;
            margin-bottom: 50px;
        }
        
        .interview-process{
            width: 800px;
            height: 532px;
            object-fit: contain;
            margin-bottom: 40px;
        }
    }
    
}
.img_1{
    width: 763px;
    height: 532px;
    object-fit: contain;
}

@media all and (min-width:768px) and (max-width:1023px){
    .container{
        width: calc(100% - 80px);
        padding: 0 40px;
        margin: 0;
        margin-top: 50px;
    }
}

@media all and (max-width:767px){
    
}