.body {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  .body_contents {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    max-width: 800px;
    .body_title {
      font-size: 25px;
      font-weight: bold;
      color: #00396e;
      width: 100%;
      height: 50px;
      margin-top: 20px;
    }
    .body_content {
      width: 100%;
      height: calc(100vh - 125px - 170px - 300px - 70px);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: bold;
    }
  }
  .body_top_bg_wrapper {
    margin-top: 125px;
    .body_top_bg {
      height: 200px;
      width: 100vw;
      object-fit: cover;
    }
  }
}
@media all and (min-width: 768px) and (max-width: 1023px) {
  .body_top_bg {
    display: none;
  }
  .body_top_bg_wrapper {
    width: 100%;
    height: 200px;
    background-color: #d8d8d8;
  }
  .body {
    .body_contents {
      display: flex;
      flex-direction: column;
    }
  }
}

@media all and (max-width: 767px) {
  .body_top_bg {
    display: none;
  }
  .body_top_bg_wrapper {
    width: 100%;
    height: 200px;
    background-color: #d8d8d8;
  }
  .body {
    .body_contents {
      display: flex;
      flex-direction: column;
    }
  }
}
