.customerservice-container {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: calc((100vw - 1520px) / 2 + 70px);
}
.customerservice-icon {
  width: 52px;
  height: 52px;
  object-fit: contain;
  margin-right: 10px;
}
.text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.text-top {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #565656;
}
.text-bot {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #565656;
}

@media (max-width: 768px) {
  .customerservice-container {
    position: relative;
    left: 20px;
    margin-top: 30px;
  }
}
