@import url("https://webfontworld.github.io/kopus/KoPubWorldDotum.css");

.container {
  margin-left: 60px;
  margin-right: 280px;
  display: flex;
  flex-direction: column;
  margin-top: 44px;
  width: 800px;
  .mission_top_wrapper {
    display: flex;
    flex-direction: row;
    width: 50%;
    height: 60px;
    border: 1px solid #dcdcdc;
    margin-bottom: 30px;
    .mission_top_content {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #666666;
      cursor: pointer;
      &:hover {
        background-color: #2d4c6a;
        color: #ffffff;
      }
      &:nth-child(4) {
        border-right: none;
      }
    }
    .mission_top_selected {
      background-color: #2d4c6a;
      color: #ffffff;
    }
  }
  .greeting_top_wrapper {
    margin-bottom: 40px;
    padding-bottom: 30px;
    border-bottom: 1px solid #dcdcdc;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .greeting_top_img {
      width: 60%;
    }
  }
  .section {
    display: flex;
    flex-direction: column;
    margin-bottom: 45px;
    width: 100%;
    .mission_imgs_wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .mission_content {
        width: calc(100% / 3);
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 90px;
        .mission_img_wrapper {
          margin-bottom: 36px;
          height: 75px;
          display: flex;
          align-items: center;
          .mission_img {
          }
        }
        .mission_content_text {
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #666666;
        }
      }
    }
    .policy_wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      .policy_content {
        width: calc(100% / 3);
        margin-bottom: 70px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .policy_title {
          font-size: 24px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #2d4c6a;
        }
        .policy_content_title {
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000000;
          margin-bottom: 20px;
        }
        .policy_img_wrapper {
          width: 100%;
          height: 50px;
          display: flex;
          justify-content: flex-start;
          margin-bottom: 12px;
          .policy_img {
          }
        }
        .policy_summary {
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000000;
          margin-bottom: 5px;
        }
        .policy_text {
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #666666;
        }
      }
    }
    .goal_top {
      display: flex;
      flex-direction: row;
      align-items: center;
      .goal_top_img {
        margin-right: 29px;
        width: 40px;
        height: 40px;
        object-fit: contain;
      }
      .goal_top_text {
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #002740;
      }
    }
    .goal_img_2 {
      margin-top: 35px;
      margin-bottom: 16px;
      background-image: url("./motto.svg");
      width: 100%;
      height: 117px;
    }
    .goal_img_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 35px;
      .goal_img_3 {
        width: calc(50% - 5px);
      }
      .goal_img_4 {
        width: calc(50% - 5px);
      }
    }
    .goal_text {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }
    .identity_title {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2d4c6a;
    }
    .identity_wrapper {
      display: flex;
      flex-direction: column;
      .identity_content_top {
        .identity_img_wrapper {
          background-image: url("identity1.svg");
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 165px;
          object-fit: contain;
        }
      }
      .identity_content_bot {
        display: flex;
        flex-direction: row;
        .identity_symbol_wrapper {
          display: flex;
          flex-direction: column;
          .symbol_img_wrapper {
            background-image: url("identity3.svg");
            display: flex;
            align-items: center;
            justify-content: center;
            width: 367px;
            height: 222px;
            object-fit: contain;
            .symbol_img {
            }
          }
        }
        .identity_color_wrapper {
          display: flex;
          flex-direction: column;
          width: 50%;
          margin-left: 33px;
          .download_wrapper {
            .download_sticky {
              border-radius: 6px;
              border: solid 1px #234c6d;
              background-color: #ffffff;
              width: calc(100% - 31px);
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 13px 0;
              margin-left: 31px;
            }
          }
          .color_wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            .color_contents {
              margin-left: 31px;
              width: calc(100% - 31px);
              display: flex;
              flex-direction: row;
              .color_content {
                width: calc(100% / 4);
                height: 95px;

                &:nth-child(1) {
                  background-color: #ff9557;
                }
                &:nth-child(2) {
                  background-color: #99d491;
                }
                &:nth-child(3) {
                  background-color: #80aadd;
                }
                &:nth-child(4) {
                  background-color: #dbdcdc;
                }
              }
            }
          }
        }
      }
    }
  }
}
.identity_subtitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0;
  .rect {
    width: 16px;
    height: 16px;
    background-color: #234c6d;
    margin-right: 15px;
  }
  .subtitle_text {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2d4c6a;
  }
}

.section_box {
  background-color: #e6effc;
  width: 100%;
  padding: 30px 65px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  .section_box_title {
    color: #223a56;
    font-size: 28px;
    font-weight: bold;
    font-family: "KoPubWorldDotum";
    line-height: 28px;
  }
  .section_box_title_sub {
    color: #223a56;
    font-size: 21px;
    font-weight: bold;
    font-family: "KoPubWorldDotum";
    line-height: 21px;
    margin-left: 2px;
  }
  .section_box_content {
    font-size: 22px;
    margin-top: 10px;
    font-family: "KoPubWorldDotum";
    color: black;
  }
}

.section_box_title_2 {
  font-size: 24px;
  font-weight: bold;
  color: black;
}

.primary_task_box {
  padding: 20px 45px;
  box-sizing: border-box;
}

.core_value_box {
  padding: 20px 45px;
  box-sizing: border-box;
  background-color: #e6effc;
}

.primary_task_img_box {
  margin-top: 40px;
  margin-bottom: 100px;
}

.primary_task_img {
  width: 100%;
}

.core_value_img_box {
  margin-top: 40px;
  margin-bottom: 120px;
  display: flex;
  justify-content: center;
}

.core_value_img {
  width: 90%;
  margin-right: 1px;
}

ul {
  list-style-type: square;
  padding-inline-start: 20px;
}

li {
  font-family: "KoPubWorldDotum";
  font-size: 16px;
}

@media all and (min-width: 768px) and (max-width: 1023px) {
  .container {
    width: calc(100% - 80px);
    padding: 0 40px;
    margin: 0;
    margin-top: 50px;
  }
}

@media all and (max-width: 767px) {
  .container {
    width: calc(100% - 40px);
    padding: 0 20px;
    margin: 0;
    margin-top: 50px;
    .greeting_top_wrapper {
      width: 100%;
      margin-bottom: 25px;
      padding-bottom: 25px;
      .greeting_top_img {
        width: 100%;
      }
    }
    .section {
      .identity_wrapper {
        .identity_content_bot {
          flex-direction: column;
          .identity_symbol_wrapper {
            .symbol_img_wrapper {
              width: calc(100% - 40px);
              padding: 0 20px;
            }
          }
          .identity_color_wrapper {
            width: 100%;
            margin-left: 0;
            .color_wrapper {
              width: 100%;
              .color_contents {
                width: calc(100% - 40px);
                padding: 0 20px;
                margin-left: 0;
                .color_content {
                }
              }
            }
            .download_wrapper {
              display: none;
            }
          }
        }
        .identity_content_top {
          .identity_img_wrapper {
            width: calc(100% - 40px);
            padding: 0 20px;
            img {
              width: 100%;
            }
          }
        }
      }
      .goal_img_2 {
        background-image: url("./motto-mobile.svg");
        background-repeat: no-repeat;
        background-position: center center;
      }
      .goal_text {
        br {
          display: none;
        }
      }
      .goal_img_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        .goal_img_3 {
          width: 100%;
          max-width: 280px;
          margin-bottom: 30px;
        }
        .goal_img_4 {
          width: 100%;
          max-width: 280px;
        }
      }
      .goal_top {
        .goal_top_text {
          font-size: 16px;
        }
      }
      .mission_imgs_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        .mission_content {
          width: 100%;
          margin-bottom: 50px;
        }
      }
      .policy_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        .policy_content {
          width: 100%;
          .policy_title {
            br {
              display: none;
            }
          }
        }
      }
    }
  }
}
