@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");
body {
  font-family: "Pretendard", sans-serif !important;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media all and (max-width: 767px) {
  body {
    overflow-x: hidden;
  }
}
