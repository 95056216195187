.container {
  margin-left: 60px;
  margin-right: 280px;
  display: flex;
  flex-direction: column;
  margin-top: 44px;
  width: 800px;
  .section {
    display: flex;
    flex-direction: column;
    margin-bottom: 45px;
    width: 100%;
    .title_normal {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }
    .datalist_wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 40px;
      justify-content: flex-start;
      row-gap: calc(80px / 3);
    }
    .top_type_wrapper {
      display: flex;
      flex-direction: row;
      width: calc(100% - 3px);
      border: 1px solid #dcdcdc;
      cursor: pointer;
      margin-bottom: 55px;
      .top_type_content {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #666666;
        width: 50%;
        padding: 18px 0;
        text-align: center;
        &:nth-child(1) {
          border-right: 1px solid #dcdcdc;
        }
        &:hover {
          background-color: #6083b2;
          color: #ffffff;
        }
      }
      .top_type_selected {
        background-color: #6083b2;
        color: #ffffff;
      }
    }
  }
}

.datacontent_wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 calc(80px / 6);
  margin-bottom: 20px;
  width: calc(100% / 4 - 20px);
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 10px;
  transition: all 0.3s;
  &:nth-child(4n + 1) {
    margin-left: 0;
  }
  &:nth-child(4n) {
    margin-right: 0;
  }
  &:hover {
    border: 1px solid #0a5a7a;
    box-shadow: 1px 0 20px rgba(0, 0, 0, 0.15);
    .datacontent_title {
      margin: 0 10px;
      padding: 10px 0;
    }
    .datacontent_download_button_wrapper {
      .datacontent_download_button {
        border-bottom-color: transparent;
        border-left-color: transparent;
        border-right-color: transparent;
      }
    }
  }
  .datacontent_title {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
    border-bottom: 2px solid #9f9f9f;
    height: 40px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .datacontent_img_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
  }
  .datacontent_img {
    width: 160px;
    height: 200px;
    background-color: #ffffff;
  }
  .datacontent_download_button_wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    .datacontent_download_button {
      color: #666666;
      border-radius: 9px;
      border: solid 1px #ededed;
      text-align: center;
      padding: 10px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 12px;
      font-size: 13px;
      font-weight: 500;
      cursor: pointer;

      &:hover {
        background-color: #0a5a7a;
        color: #ffffff;
        & > svg {
          fill: #ffffff;
        }
        .datacontent_download_img_white {
          display: block;
        }
        .datacontent_download_img_black {
          display: none;
        }
      }

      .datacontent_download_img_black {
        width: 20px;
        height: 20px;
        object-fit: contain;
      }
      .datacontent_download_img_white {
        width: 20px;
        height: 20px;
        object-fit: contain;
        display: none;
      }
    }
  }
}

@media all and (min-width: 768px) and (max-width: 1023px) {
}

@media all and (max-width: 767px) {
}

.pagenumberlist {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;
  align-items: center;
  .previous {
    width: 0px;
    height: 0px;
    border-top: 7.5px solid transparent;
    border-right: 15px solid #525252;
    border-bottom: 7.5px solid transparent;
    margin-right: 8px;
  }
  .next {
    width: 0px;
    height: 0px;
    border-top: 7.5px solid transparent;
    border-left: 15px solid #525252;
    border-bottom: 7.5px solid transparent;
    margin-left: 8px;
  }
}

.pagenum_wrapper {
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: rgba(72, 110, 153, 0.1);
  }
  .pagenum {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #616161;
    cursor: pointer;
  }
}
.pagenum_selected {
  width: 40px;
  height: 40px;
  background-color: rgba(72, 110, 153, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  .pagenum {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #486e99;
    cursor: pointer;
  }
}
@media all and (min-width: 768px) and (max-width: 1023px) {
  .container {
    width: calc(100% - 80px);
    padding: 0 40px;
    margin: 0;
    margin-top: 50px;
  }
}

@media all and (max-width: 767px) {
  .container {
    width: calc(100% - 40px);
    padding: 0 20px;
    margin: 0;
    margin-top: 50px;
    .section {
      .datalist_wrapper {
        .datacontent_wrapper {
          width: calc(100% / 2);
          .datacontent_img {
            padding: 0;
          }
        }
      }
    }
  }
}
